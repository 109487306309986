
// @ts-nocheck


export const localeCodes =  [
  "zh-cn",
  "zh-hk",
  "zh-tw",
  "en-us",
  "en-xx",
  "en-gb",
  "en-hk",
  "de-de",
  "en-sg",
  "en-au",
  "en-my",
  "en-ph",
  "en-nz",
  "en-ca",
  "ja-jp"
]

export const localeLoaders = {
  "zh-cn": [{ key: "../src/locales/zh-hk.json", load: () => import("../src/locales/zh-hk.json" /* webpackChunkName: "locale__data_jenkinsslave_workspace_furt_hopegoo_web_pc_stage_src_locales_zh_hk_json" */), cache: true }],
  "zh-hk": [{ key: "../src/locales/zh-hk.json", load: () => import("../src/locales/zh-hk.json" /* webpackChunkName: "locale__data_jenkinsslave_workspace_furt_hopegoo_web_pc_stage_src_locales_zh_hk_json" */), cache: true }],
  "zh-tw": [{ key: "../src/locales/zh-tw.json", load: () => import("../src/locales/zh-tw.json" /* webpackChunkName: "locale__data_jenkinsslave_workspace_furt_hopegoo_web_pc_stage_src_locales_zh_tw_json" */), cache: true }],
  "en-us": [{ key: "../src/locales/en-us.json", load: () => import("../src/locales/en-us.json" /* webpackChunkName: "locale__data_jenkinsslave_workspace_furt_hopegoo_web_pc_stage_src_locales_en_us_json" */), cache: true }],
  "en-xx": [{ key: "../src/locales/en-us.json", load: () => import("../src/locales/en-us.json" /* webpackChunkName: "locale__data_jenkinsslave_workspace_furt_hopegoo_web_pc_stage_src_locales_en_us_json" */), cache: true }],
  "en-gb": [{ key: "../src/locales/en-gb.json", load: () => import("../src/locales/en-gb.json" /* webpackChunkName: "locale__data_jenkinsslave_workspace_furt_hopegoo_web_pc_stage_src_locales_en_gb_json" */), cache: true }],
  "en-hk": [{ key: "../src/locales/en-hk.json", load: () => import("../src/locales/en-hk.json" /* webpackChunkName: "locale__data_jenkinsslave_workspace_furt_hopegoo_web_pc_stage_src_locales_en_hk_json" */), cache: true }],
  "de-de": [{ key: "../src/locales/de-de.json", load: () => import("../src/locales/de-de.json" /* webpackChunkName: "locale__data_jenkinsslave_workspace_furt_hopegoo_web_pc_stage_src_locales_de_de_json" */), cache: true }],
  "en-sg": [{ key: "../src/locales/en-sg.json", load: () => import("../src/locales/en-sg.json" /* webpackChunkName: "locale__data_jenkinsslave_workspace_furt_hopegoo_web_pc_stage_src_locales_en_sg_json" */), cache: true }],
  "en-au": [{ key: "../src/locales/en-au.json", load: () => import("../src/locales/en-au.json" /* webpackChunkName: "locale__data_jenkinsslave_workspace_furt_hopegoo_web_pc_stage_src_locales_en_au_json" */), cache: true }],
  "en-my": [{ key: "../src/locales/en-my.json", load: () => import("../src/locales/en-my.json" /* webpackChunkName: "locale__data_jenkinsslave_workspace_furt_hopegoo_web_pc_stage_src_locales_en_my_json" */), cache: true }],
  "en-ph": [{ key: "../src/locales/en-ph.json", load: () => import("../src/locales/en-ph.json" /* webpackChunkName: "locale__data_jenkinsslave_workspace_furt_hopegoo_web_pc_stage_src_locales_en_ph_json" */), cache: true }],
  "en-nz": [{ key: "../src/locales/en-nz.json", load: () => import("../src/locales/en-nz.json" /* webpackChunkName: "locale__data_jenkinsslave_workspace_furt_hopegoo_web_pc_stage_src_locales_en_nz_json" */), cache: true }],
  "en-ca": [{ key: "../src/locales/en-ca.json", load: () => import("../src/locales/en-ca.json" /* webpackChunkName: "locale__data_jenkinsslave_workspace_furt_hopegoo_web_pc_stage_src_locales_en_ca_json" */), cache: true }],
  "ja-jp": [{ key: "../src/locales/ja-jp.json", load: () => import("../src/locales/ja-jp.json" /* webpackChunkName: "locale__data_jenkinsslave_workspace_furt_hopegoo_web_pc_stage_src_locales_ja_jp_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../vue-i18n.options.ts?hash=0a677049&config=1" /* webpackChunkName: "__vue_i18n_options_ts_0a677049" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./vue-i18n.options.ts",
  "locales": [
    {
      "code": "zh-cn",
      "name": "简体中文",
      "iso": "zh-cn",
      "files": [
        "locales/zh-hk.json"
      ]
    },
    {
      "code": "zh-hk",
      "name": "繁體中文 (香港)",
      "iso": "zh-hk",
      "files": [
        "locales/zh-hk.json"
      ]
    },
    {
      "code": "zh-tw",
      "name": "繁體中文 (台湾)",
      "iso": "zh-tw",
      "files": [
        "locales/zh-tw.json"
      ]
    },
    {
      "code": "en-us",
      "name": "English (United States)",
      "iso": "en-us",
      "files": [
        "locales/en-us.json"
      ]
    },
    {
      "code": "en-xx",
      "name": "English",
      "iso": "en-xx",
      "files": [
        "locales/en-us.json"
      ]
    },
    {
      "code": "en-gb",
      "name": "English (United Kingdom)",
      "iso": "en-gb",
      "files": [
        "locales/en-gb.json"
      ]
    },
    {
      "code": "en-hk",
      "name": "English (Hong Kong)",
      "iso": "en-hk",
      "files": [
        "locales/en-hk.json"
      ]
    },
    {
      "code": "de-de",
      "name": "Deutsch",
      "iso": "de-de",
      "files": [
        "locales/de-de.json"
      ]
    },
    {
      "code": "en-sg",
      "name": "English (Singapore)",
      "iso": "en-sg",
      "files": [
        "locales/en-sg.json"
      ]
    },
    {
      "code": "en-au",
      "name": "English (Australia)",
      "iso": "en-au",
      "files": [
        "locales/en-au.json"
      ]
    },
    {
      "code": "en-my",
      "name": "English (Malaysia)",
      "iso": "en-my",
      "files": [
        "locales/en-my.json"
      ]
    },
    {
      "code": "en-ph",
      "name": "English (Philippines)",
      "iso": "en-ph",
      "files": [
        "locales/en-ph.json"
      ]
    },
    {
      "code": "en-nz",
      "name": "English (New Zealand)",
      "iso": "en-nz",
      "files": [
        "locales/en-nz.json"
      ]
    },
    {
      "code": "en-ca",
      "name": "English (Canada)",
      "iso": "en-ca",
      "files": [
        "locales/en-ca.json"
      ]
    },
    {
      "code": "ja-jp",
      "name": "日本語",
      "iso": "ja-jp",
      "files": [
        "locales/ja-jp.json"
      ]
    }
  ],
  "defaultLocale": "en-xx",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_and_default",
  "lazy": true,
  "langDir": "locales/",
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": true,
    "cookieCrossOrigin": false,
    "cookieDomain": ".hopegoo.com",
    "cookieKey": "locale",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const nuxtI18nOptionsDefault = {
  "experimental": {
    "localeDetector": ""
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "",
  "locales": [],
  "defaultLocale": "",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": false,
  "langDir": null,
  "rootRedirect": null,
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "i18n_redirected",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false
}

export const normalizedLocales = [
  {
    "code": "zh-cn",
    "name": "简体中文",
    "iso": "zh-cn",
    "files": [
      {
        "path": "locales/zh-hk.json"
      }
    ]
  },
  {
    "code": "zh-hk",
    "name": "繁體中文 (香港)",
    "iso": "zh-hk",
    "files": [
      {
        "path": "locales/zh-hk.json"
      }
    ]
  },
  {
    "code": "zh-tw",
    "name": "繁體中文 (台湾)",
    "iso": "zh-tw",
    "files": [
      {
        "path": "locales/zh-tw.json"
      }
    ]
  },
  {
    "code": "en-us",
    "name": "English (United States)",
    "iso": "en-us",
    "files": [
      {
        "path": "locales/en-us.json"
      }
    ]
  },
  {
    "code": "en-xx",
    "name": "English",
    "iso": "en-xx",
    "files": [
      {
        "path": "locales/en-us.json"
      }
    ]
  },
  {
    "code": "en-gb",
    "name": "English (United Kingdom)",
    "iso": "en-gb",
    "files": [
      {
        "path": "locales/en-gb.json"
      }
    ]
  },
  {
    "code": "en-hk",
    "name": "English (Hong Kong)",
    "iso": "en-hk",
    "files": [
      {
        "path": "locales/en-hk.json"
      }
    ]
  },
  {
    "code": "de-de",
    "name": "Deutsch",
    "iso": "de-de",
    "files": [
      {
        "path": "locales/de-de.json"
      }
    ]
  },
  {
    "code": "en-sg",
    "name": "English (Singapore)",
    "iso": "en-sg",
    "files": [
      {
        "path": "locales/en-sg.json"
      }
    ]
  },
  {
    "code": "en-au",
    "name": "English (Australia)",
    "iso": "en-au",
    "files": [
      {
        "path": "locales/en-au.json"
      }
    ]
  },
  {
    "code": "en-my",
    "name": "English (Malaysia)",
    "iso": "en-my",
    "files": [
      {
        "path": "locales/en-my.json"
      }
    ]
  },
  {
    "code": "en-ph",
    "name": "English (Philippines)",
    "iso": "en-ph",
    "files": [
      {
        "path": "locales/en-ph.json"
      }
    ]
  },
  {
    "code": "en-nz",
    "name": "English (New Zealand)",
    "iso": "en-nz",
    "files": [
      {
        "path": "locales/en-nz.json"
      }
    ]
  },
  {
    "code": "en-ca",
    "name": "English (Canada)",
    "iso": "en-ca",
    "files": [
      {
        "path": "locales/en-ca.json"
      }
    ]
  },
  {
    "code": "ja-jp",
    "name": "日本語",
    "iso": "ja-jp",
    "files": [
      {
        "path": "locales/ja-jp.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const STRATEGIES = {
  "PREFIX": "prefix",
  "PREFIX_EXCEPT_DEFAULT": "prefix_except_default",
  "PREFIX_AND_DEFAULT": "prefix_and_default",
  "NO_PREFIX": "no_prefix"
}
export const DEFAULT_LOCALE = ""
export const DEFAULT_STRATEGY = "prefix_except_default"
export const DEFAULT_TRAILING_SLASH = false
export const DEFAULT_ROUTES_NAME_SEPARATOR = "___"
export const DEFAULT_LOCALE_ROUTE_NAME_SUFFIX = "default"
export const DEFAULT_DETECTION_DIRECTION = "ltr"
export const DEFAULT_BASE_URL = ""
export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"


