export default defineNuxtPlugin((nuxtApp) => {
  if (nuxtApp.vueApp) {
    vueApp.setApp(nuxtApp.vueApp)

    if (process.client) {
    // 监控vue错误，上报天穹
      const tq = window.tq
      if (tq) {
        nuxtApp.vueApp.config.errorHandler = tq.vueErrorHandler.bind(tq)
      }
    }
  }
})
