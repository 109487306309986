import { default as _4049ayO1p9ymIMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/404.vue?macro=true";
import { default as CoinsList9GSUUPzIn7Meta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/coinsList/CoinsList.vue?macro=true";
import { default as Empty27AmXcNK1lMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/empty/Empty.vue?macro=true";
import { default as LayoutuyvzK1rDKBMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/layout/Layout.vue?macro=true";
import { default as indexrtZvlzULaIMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/index.vue?macro=true";
import { default as ruleWOqcYUqmNsMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/rule.vue?macro=true";
import { default as how_45we_45started0eOnR0FVp8Meta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/how-we-started.vue?macro=true";
import { default as index24anZso1gsMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/index.vue?macro=true";
import { default as why_45book_45with_45usLgW4crFpUsMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/why-book-with-us.vue?macro=true";
import { default as business_45licenserU23tjx7IdMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/business-license.vue?macro=true";
import { default as callservicecdC89zyJVGMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/callservice.vue?macro=true";
import { default as indexVyD9HzvOqeMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/index.vue?macro=true";
import { default as email_45usq2xLyi7zAmMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/email-us.vue?macro=true";
import { default as faqN5VgaJD2WuMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/faq.vue?macro=true";
import { default as feedbackUPBq85IjxoMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/feedback.vue?macro=true";
import { default as privacy_45policyLuBphRQGDMMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/privacy-policy.vue?macro=true";
import { default as submit_45questionwMm2hPTXVeMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/submit-question.vue?macro=true";
import { default as subscriptionWzfwYtdYhzMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/subscription.vue?macro=true";
import { default as terms_45and_45conditionsHpj0SphKelMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/terms-and-conditions.vue?macro=true";
import { default as unsubscribeD10JhVaQ76Meta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/unsubscribe.vue?macro=true";
import { default as indexfPGbsukTANMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coupon/index.vue?macro=true";
import { default as index7OH1QyEmn7Meta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/index.vue?macro=true";
import { default as fillJejb8IF4pvMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/fill.vue?macro=true";
import { default as listEGfQPQPlq2Meta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/list.vue?macro=true";
import { default as indexNH28lbsV1EMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/keepalive/index.vue?macro=true";
import { default as main_45flightsOg7UIS7lMKMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-flights.vue?macro=true";
import { default as main_45hotels5VNrZ22TGHMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-hotels.vue?macro=true";
import { default as main_45trainsuWxqKmPZoJMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-trains.vue?macro=true";
import { default as changepassword1BVRWpzsUGMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/changepassword.vue?macro=true";
import { default as collectionswYdCwN40mKMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/collections.vue?macro=true";
import { default as couponUYZlguEzPzMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/coupon.vue?macro=true";
import { default as deleteaccountlojqBNBG7yMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/deleteaccount.vue?macro=true";
import { default as orderl3ibVSk7GtMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/order.vue?macro=true";
import { default as trRowL1StkmSpg0Meta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/components/trRow.vue?macro=true";
import { default as editDsPwRBxZn2Meta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/edit.vue?macro=true";
import { default as listo0W0oOiCUpMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/list.vue?macro=true";
import { default as profileJHPETAwI8pMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/profile.vue?macro=true";
import { default as TravelerCardOufxdNH7o2Meta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerCard.vue?macro=true";
import { default as TravelerFormDYo0rv7HyQMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerForm.vue?macro=true";
import { default as indexMkeUedyeLCMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/index.vue?macro=true";
import { default as search3fNQMR7BKeMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/order/search.vue?macro=true";
import { default as forgotpasswordEHAdnwYpDhMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/forgotpassword.vue?macro=true";
import { default as signinp1GjVW5OJVMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signin.vue?macro=true";
import { default as signupcZ40DdedxdMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signup.vue?macro=true";
import { default as trackerYySgjvclYfMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/tracker.ts?macro=true";
import { default as indexzkPdhhO1BUMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pc/keepalive/index.vue?macro=true";
import { default as pinia9WoESw3BI6Meta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pinia.vue?macro=true";
import { default as indexwRUFvyc68pMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/testing/index.vue?macro=true";
import { default as _91nameId_93Lxuau2Hwo5Meta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/travelguide/[nameId].vue?macro=true";
import { default as indexhNRm4TyEPiMeta } from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/unsubscribe/index.vue?macro=true";
export default [
  {
    name: "404___zh-cn",
    path: "/zh-cn/404",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/404.vue")
  },
  {
    name: "404___zh-hk",
    path: "/zh-hk/404",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/404.vue")
  },
  {
    name: "404___zh-tw",
    path: "/zh-tw/404",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/404.vue")
  },
  {
    name: "404___en-us",
    path: "/en-us/404",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/404.vue")
  },
  {
    name: "404___en-xx___default",
    path: "/404",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/404.vue")
  },
  {
    name: "404___en-xx",
    path: "/en-xx/404",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/404.vue")
  },
  {
    name: "404___en-gb",
    path: "/en-gb/404",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/404.vue")
  },
  {
    name: "404___en-hk",
    path: "/en-hk/404",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/404.vue")
  },
  {
    name: "404___de-de",
    path: "/de-de/404",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/404.vue")
  },
  {
    name: "404___en-sg",
    path: "/en-sg/404",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/404.vue")
  },
  {
    name: "404___en-au",
    path: "/en-au/404",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/404.vue")
  },
  {
    name: "404___en-my",
    path: "/en-my/404",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/404.vue")
  },
  {
    name: "404___en-ph",
    path: "/en-ph/404",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/404.vue")
  },
  {
    name: "404___en-nz",
    path: "/en-nz/404",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/404.vue")
  },
  {
    name: "404___en-ca",
    path: "/en-ca/404",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/404.vue")
  },
  {
    name: "404___ja-jp",
    path: "/ja-jp/404",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/404.vue")
  },
  {
    name: "coins-components-coinsList-CoinsList___zh-cn",
    path: "/zh-cn/coins/components/coinsList/CoinsList",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/coinsList/CoinsList.vue")
  },
  {
    name: "coins-components-coinsList-CoinsList___zh-hk",
    path: "/zh-hk/coins/components/coinsList/CoinsList",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/coinsList/CoinsList.vue")
  },
  {
    name: "coins-components-coinsList-CoinsList___zh-tw",
    path: "/zh-tw/coins/components/coinsList/CoinsList",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/coinsList/CoinsList.vue")
  },
  {
    name: "coins-components-coinsList-CoinsList___en-us",
    path: "/en-us/coins/components/coinsList/CoinsList",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/coinsList/CoinsList.vue")
  },
  {
    name: "coins-components-coinsList-CoinsList___en-xx___default",
    path: "/coins/components/coinsList/CoinsList",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/coinsList/CoinsList.vue")
  },
  {
    name: "coins-components-coinsList-CoinsList___en-xx",
    path: "/en-xx/coins/components/coinsList/CoinsList",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/coinsList/CoinsList.vue")
  },
  {
    name: "coins-components-coinsList-CoinsList___en-gb",
    path: "/en-gb/coins/components/coinsList/CoinsList",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/coinsList/CoinsList.vue")
  },
  {
    name: "coins-components-coinsList-CoinsList___en-hk",
    path: "/en-hk/coins/components/coinsList/CoinsList",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/coinsList/CoinsList.vue")
  },
  {
    name: "coins-components-coinsList-CoinsList___de-de",
    path: "/de-de/coins/components/coinsList/CoinsList",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/coinsList/CoinsList.vue")
  },
  {
    name: "coins-components-coinsList-CoinsList___en-sg",
    path: "/en-sg/coins/components/coinsList/CoinsList",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/coinsList/CoinsList.vue")
  },
  {
    name: "coins-components-coinsList-CoinsList___en-au",
    path: "/en-au/coins/components/coinsList/CoinsList",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/coinsList/CoinsList.vue")
  },
  {
    name: "coins-components-coinsList-CoinsList___en-my",
    path: "/en-my/coins/components/coinsList/CoinsList",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/coinsList/CoinsList.vue")
  },
  {
    name: "coins-components-coinsList-CoinsList___en-ph",
    path: "/en-ph/coins/components/coinsList/CoinsList",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/coinsList/CoinsList.vue")
  },
  {
    name: "coins-components-coinsList-CoinsList___en-nz",
    path: "/en-nz/coins/components/coinsList/CoinsList",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/coinsList/CoinsList.vue")
  },
  {
    name: "coins-components-coinsList-CoinsList___en-ca",
    path: "/en-ca/coins/components/coinsList/CoinsList",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/coinsList/CoinsList.vue")
  },
  {
    name: "coins-components-coinsList-CoinsList___ja-jp",
    path: "/ja-jp/coins/components/coinsList/CoinsList",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/coinsList/CoinsList.vue")
  },
  {
    name: "coins-components-empty-Empty___zh-cn",
    path: "/zh-cn/coins/components/empty/Empty",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/empty/Empty.vue")
  },
  {
    name: "coins-components-empty-Empty___zh-hk",
    path: "/zh-hk/coins/components/empty/Empty",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/empty/Empty.vue")
  },
  {
    name: "coins-components-empty-Empty___zh-tw",
    path: "/zh-tw/coins/components/empty/Empty",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/empty/Empty.vue")
  },
  {
    name: "coins-components-empty-Empty___en-us",
    path: "/en-us/coins/components/empty/Empty",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/empty/Empty.vue")
  },
  {
    name: "coins-components-empty-Empty___en-xx___default",
    path: "/coins/components/empty/Empty",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/empty/Empty.vue")
  },
  {
    name: "coins-components-empty-Empty___en-xx",
    path: "/en-xx/coins/components/empty/Empty",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/empty/Empty.vue")
  },
  {
    name: "coins-components-empty-Empty___en-gb",
    path: "/en-gb/coins/components/empty/Empty",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/empty/Empty.vue")
  },
  {
    name: "coins-components-empty-Empty___en-hk",
    path: "/en-hk/coins/components/empty/Empty",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/empty/Empty.vue")
  },
  {
    name: "coins-components-empty-Empty___de-de",
    path: "/de-de/coins/components/empty/Empty",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/empty/Empty.vue")
  },
  {
    name: "coins-components-empty-Empty___en-sg",
    path: "/en-sg/coins/components/empty/Empty",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/empty/Empty.vue")
  },
  {
    name: "coins-components-empty-Empty___en-au",
    path: "/en-au/coins/components/empty/Empty",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/empty/Empty.vue")
  },
  {
    name: "coins-components-empty-Empty___en-my",
    path: "/en-my/coins/components/empty/Empty",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/empty/Empty.vue")
  },
  {
    name: "coins-components-empty-Empty___en-ph",
    path: "/en-ph/coins/components/empty/Empty",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/empty/Empty.vue")
  },
  {
    name: "coins-components-empty-Empty___en-nz",
    path: "/en-nz/coins/components/empty/Empty",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/empty/Empty.vue")
  },
  {
    name: "coins-components-empty-Empty___en-ca",
    path: "/en-ca/coins/components/empty/Empty",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/empty/Empty.vue")
  },
  {
    name: "coins-components-empty-Empty___ja-jp",
    path: "/ja-jp/coins/components/empty/Empty",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/empty/Empty.vue")
  },
  {
    name: "coins-components-layout-Layout___zh-cn",
    path: "/zh-cn/coins/components/layout/Layout",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/layout/Layout.vue")
  },
  {
    name: "coins-components-layout-Layout___zh-hk",
    path: "/zh-hk/coins/components/layout/Layout",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/layout/Layout.vue")
  },
  {
    name: "coins-components-layout-Layout___zh-tw",
    path: "/zh-tw/coins/components/layout/Layout",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/layout/Layout.vue")
  },
  {
    name: "coins-components-layout-Layout___en-us",
    path: "/en-us/coins/components/layout/Layout",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/layout/Layout.vue")
  },
  {
    name: "coins-components-layout-Layout___en-xx___default",
    path: "/coins/components/layout/Layout",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/layout/Layout.vue")
  },
  {
    name: "coins-components-layout-Layout___en-xx",
    path: "/en-xx/coins/components/layout/Layout",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/layout/Layout.vue")
  },
  {
    name: "coins-components-layout-Layout___en-gb",
    path: "/en-gb/coins/components/layout/Layout",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/layout/Layout.vue")
  },
  {
    name: "coins-components-layout-Layout___en-hk",
    path: "/en-hk/coins/components/layout/Layout",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/layout/Layout.vue")
  },
  {
    name: "coins-components-layout-Layout___de-de",
    path: "/de-de/coins/components/layout/Layout",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/layout/Layout.vue")
  },
  {
    name: "coins-components-layout-Layout___en-sg",
    path: "/en-sg/coins/components/layout/Layout",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/layout/Layout.vue")
  },
  {
    name: "coins-components-layout-Layout___en-au",
    path: "/en-au/coins/components/layout/Layout",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/layout/Layout.vue")
  },
  {
    name: "coins-components-layout-Layout___en-my",
    path: "/en-my/coins/components/layout/Layout",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/layout/Layout.vue")
  },
  {
    name: "coins-components-layout-Layout___en-ph",
    path: "/en-ph/coins/components/layout/Layout",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/layout/Layout.vue")
  },
  {
    name: "coins-components-layout-Layout___en-nz",
    path: "/en-nz/coins/components/layout/Layout",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/layout/Layout.vue")
  },
  {
    name: "coins-components-layout-Layout___en-ca",
    path: "/en-ca/coins/components/layout/Layout",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/layout/Layout.vue")
  },
  {
    name: "coins-components-layout-Layout___ja-jp",
    path: "/ja-jp/coins/components/layout/Layout",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/components/layout/Layout.vue")
  },
  {
    name: "coins___zh-cn",
    path: "/zh-cn/coins",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/index.vue")
  },
  {
    name: "coins___zh-hk",
    path: "/zh-hk/coins",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/index.vue")
  },
  {
    name: "coins___zh-tw",
    path: "/zh-tw/coins",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/index.vue")
  },
  {
    name: "coins___en-us",
    path: "/en-us/coins",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/index.vue")
  },
  {
    name: "coins___en-xx___default",
    path: "/coins",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/index.vue")
  },
  {
    name: "coins___en-xx",
    path: "/en-xx/coins",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/index.vue")
  },
  {
    name: "coins___en-gb",
    path: "/en-gb/coins",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/index.vue")
  },
  {
    name: "coins___en-hk",
    path: "/en-hk/coins",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/index.vue")
  },
  {
    name: "coins___de-de",
    path: "/de-de/coins",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/index.vue")
  },
  {
    name: "coins___en-sg",
    path: "/en-sg/coins",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/index.vue")
  },
  {
    name: "coins___en-au",
    path: "/en-au/coins",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/index.vue")
  },
  {
    name: "coins___en-my",
    path: "/en-my/coins",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/index.vue")
  },
  {
    name: "coins___en-ph",
    path: "/en-ph/coins",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/index.vue")
  },
  {
    name: "coins___en-nz",
    path: "/en-nz/coins",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/index.vue")
  },
  {
    name: "coins___en-ca",
    path: "/en-ca/coins",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/index.vue")
  },
  {
    name: "coins___ja-jp",
    path: "/ja-jp/coins",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/index.vue")
  },
  {
    name: "coins-rule___zh-cn",
    path: "/zh-cn/coins/rule",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/rule.vue")
  },
  {
    name: "coins-rule___zh-hk",
    path: "/zh-hk/coins/rule",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/rule.vue")
  },
  {
    name: "coins-rule___zh-tw",
    path: "/zh-tw/coins/rule",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/rule.vue")
  },
  {
    name: "coins-rule___en-us",
    path: "/en-us/coins/rule",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/rule.vue")
  },
  {
    name: "coins-rule___en-xx___default",
    path: "/coins/rule",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/rule.vue")
  },
  {
    name: "coins-rule___en-xx",
    path: "/en-xx/coins/rule",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/rule.vue")
  },
  {
    name: "coins-rule___en-gb",
    path: "/en-gb/coins/rule",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/rule.vue")
  },
  {
    name: "coins-rule___en-hk",
    path: "/en-hk/coins/rule",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/rule.vue")
  },
  {
    name: "coins-rule___de-de",
    path: "/de-de/coins/rule",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/rule.vue")
  },
  {
    name: "coins-rule___en-sg",
    path: "/en-sg/coins/rule",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/rule.vue")
  },
  {
    name: "coins-rule___en-au",
    path: "/en-au/coins/rule",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/rule.vue")
  },
  {
    name: "coins-rule___en-my",
    path: "/en-my/coins/rule",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/rule.vue")
  },
  {
    name: "coins-rule___en-ph",
    path: "/en-ph/coins/rule",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/rule.vue")
  },
  {
    name: "coins-rule___en-nz",
    path: "/en-nz/coins/rule",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/rule.vue")
  },
  {
    name: "coins-rule___en-ca",
    path: "/en-ca/coins/rule",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/rule.vue")
  },
  {
    name: "coins-rule___ja-jp",
    path: "/ja-jp/coins/rule",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coins/rule.vue")
  },
  {
    name: "common-about-us-how-we-started___zh-cn",
    path: "/zh-cn/common/about-us/how-we-started",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/how-we-started.vue")
  },
  {
    name: "common-about-us-how-we-started___zh-hk",
    path: "/zh-hk/common/about-us/how-we-started",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/how-we-started.vue")
  },
  {
    name: "common-about-us-how-we-started___zh-tw",
    path: "/zh-tw/common/about-us/how-we-started",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/how-we-started.vue")
  },
  {
    name: "common-about-us-how-we-started___en-us",
    path: "/en-us/common/about-us/how-we-started",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/how-we-started.vue")
  },
  {
    name: "common-about-us-how-we-started___en-xx___default",
    path: "/common/about-us/how-we-started",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/how-we-started.vue")
  },
  {
    name: "common-about-us-how-we-started___en-xx",
    path: "/en-xx/common/about-us/how-we-started",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/how-we-started.vue")
  },
  {
    name: "common-about-us-how-we-started___en-gb",
    path: "/en-gb/common/about-us/how-we-started",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/how-we-started.vue")
  },
  {
    name: "common-about-us-how-we-started___en-hk",
    path: "/en-hk/common/about-us/how-we-started",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/how-we-started.vue")
  },
  {
    name: "common-about-us-how-we-started___de-de",
    path: "/de-de/common/about-us/how-we-started",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/how-we-started.vue")
  },
  {
    name: "common-about-us-how-we-started___en-sg",
    path: "/en-sg/common/about-us/how-we-started",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/how-we-started.vue")
  },
  {
    name: "common-about-us-how-we-started___en-au",
    path: "/en-au/common/about-us/how-we-started",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/how-we-started.vue")
  },
  {
    name: "common-about-us-how-we-started___en-my",
    path: "/en-my/common/about-us/how-we-started",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/how-we-started.vue")
  },
  {
    name: "common-about-us-how-we-started___en-ph",
    path: "/en-ph/common/about-us/how-we-started",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/how-we-started.vue")
  },
  {
    name: "common-about-us-how-we-started___en-nz",
    path: "/en-nz/common/about-us/how-we-started",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/how-we-started.vue")
  },
  {
    name: "common-about-us-how-we-started___en-ca",
    path: "/en-ca/common/about-us/how-we-started",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/how-we-started.vue")
  },
  {
    name: "common-about-us-how-we-started___ja-jp",
    path: "/ja-jp/common/about-us/how-we-started",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/how-we-started.vue")
  },
  {
    name: "common-about-us___zh-cn",
    path: "/zh-cn/common/about-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/index.vue")
  },
  {
    name: "common-about-us___zh-hk",
    path: "/zh-hk/common/about-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/index.vue")
  },
  {
    name: "common-about-us___zh-tw",
    path: "/zh-tw/common/about-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/index.vue")
  },
  {
    name: "common-about-us___en-us",
    path: "/en-us/common/about-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/index.vue")
  },
  {
    name: "common-about-us___en-xx___default",
    path: "/common/about-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/index.vue")
  },
  {
    name: "common-about-us___en-xx",
    path: "/en-xx/common/about-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/index.vue")
  },
  {
    name: "common-about-us___en-gb",
    path: "/en-gb/common/about-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/index.vue")
  },
  {
    name: "common-about-us___en-hk",
    path: "/en-hk/common/about-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/index.vue")
  },
  {
    name: "common-about-us___de-de",
    path: "/de-de/common/about-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/index.vue")
  },
  {
    name: "common-about-us___en-sg",
    path: "/en-sg/common/about-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/index.vue")
  },
  {
    name: "common-about-us___en-au",
    path: "/en-au/common/about-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/index.vue")
  },
  {
    name: "common-about-us___en-my",
    path: "/en-my/common/about-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/index.vue")
  },
  {
    name: "common-about-us___en-ph",
    path: "/en-ph/common/about-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/index.vue")
  },
  {
    name: "common-about-us___en-nz",
    path: "/en-nz/common/about-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/index.vue")
  },
  {
    name: "common-about-us___en-ca",
    path: "/en-ca/common/about-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/index.vue")
  },
  {
    name: "common-about-us___ja-jp",
    path: "/ja-jp/common/about-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/index.vue")
  },
  {
    name: "common-about-us-why-book-with-us___zh-cn",
    path: "/zh-cn/common/about-us/why-book-with-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/why-book-with-us.vue")
  },
  {
    name: "common-about-us-why-book-with-us___zh-hk",
    path: "/zh-hk/common/about-us/why-book-with-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/why-book-with-us.vue")
  },
  {
    name: "common-about-us-why-book-with-us___zh-tw",
    path: "/zh-tw/common/about-us/why-book-with-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/why-book-with-us.vue")
  },
  {
    name: "common-about-us-why-book-with-us___en-us",
    path: "/en-us/common/about-us/why-book-with-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/why-book-with-us.vue")
  },
  {
    name: "common-about-us-why-book-with-us___en-xx___default",
    path: "/common/about-us/why-book-with-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/why-book-with-us.vue")
  },
  {
    name: "common-about-us-why-book-with-us___en-xx",
    path: "/en-xx/common/about-us/why-book-with-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/why-book-with-us.vue")
  },
  {
    name: "common-about-us-why-book-with-us___en-gb",
    path: "/en-gb/common/about-us/why-book-with-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/why-book-with-us.vue")
  },
  {
    name: "common-about-us-why-book-with-us___en-hk",
    path: "/en-hk/common/about-us/why-book-with-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/why-book-with-us.vue")
  },
  {
    name: "common-about-us-why-book-with-us___de-de",
    path: "/de-de/common/about-us/why-book-with-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/why-book-with-us.vue")
  },
  {
    name: "common-about-us-why-book-with-us___en-sg",
    path: "/en-sg/common/about-us/why-book-with-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/why-book-with-us.vue")
  },
  {
    name: "common-about-us-why-book-with-us___en-au",
    path: "/en-au/common/about-us/why-book-with-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/why-book-with-us.vue")
  },
  {
    name: "common-about-us-why-book-with-us___en-my",
    path: "/en-my/common/about-us/why-book-with-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/why-book-with-us.vue")
  },
  {
    name: "common-about-us-why-book-with-us___en-ph",
    path: "/en-ph/common/about-us/why-book-with-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/why-book-with-us.vue")
  },
  {
    name: "common-about-us-why-book-with-us___en-nz",
    path: "/en-nz/common/about-us/why-book-with-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/why-book-with-us.vue")
  },
  {
    name: "common-about-us-why-book-with-us___en-ca",
    path: "/en-ca/common/about-us/why-book-with-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/why-book-with-us.vue")
  },
  {
    name: "common-about-us-why-book-with-us___ja-jp",
    path: "/ja-jp/common/about-us/why-book-with-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/about-us/why-book-with-us.vue")
  },
  {
    name: "common-business-license___zh-cn",
    path: "/zh-cn/common/business-license",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/business-license.vue")
  },
  {
    name: "common-business-license___zh-hk",
    path: "/zh-hk/common/business-license",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/business-license.vue")
  },
  {
    name: "common-business-license___zh-tw",
    path: "/zh-tw/common/business-license",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/business-license.vue")
  },
  {
    name: "common-business-license___en-us",
    path: "/en-us/common/business-license",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/business-license.vue")
  },
  {
    name: "common-business-license___en-xx___default",
    path: "/common/business-license",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/business-license.vue")
  },
  {
    name: "common-business-license___en-xx",
    path: "/en-xx/common/business-license",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/business-license.vue")
  },
  {
    name: "common-business-license___en-gb",
    path: "/en-gb/common/business-license",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/business-license.vue")
  },
  {
    name: "common-business-license___en-hk",
    path: "/en-hk/common/business-license",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/business-license.vue")
  },
  {
    name: "common-business-license___de-de",
    path: "/de-de/common/business-license",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/business-license.vue")
  },
  {
    name: "common-business-license___en-sg",
    path: "/en-sg/common/business-license",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/business-license.vue")
  },
  {
    name: "common-business-license___en-au",
    path: "/en-au/common/business-license",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/business-license.vue")
  },
  {
    name: "common-business-license___en-my",
    path: "/en-my/common/business-license",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/business-license.vue")
  },
  {
    name: "common-business-license___en-ph",
    path: "/en-ph/common/business-license",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/business-license.vue")
  },
  {
    name: "common-business-license___en-nz",
    path: "/en-nz/common/business-license",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/business-license.vue")
  },
  {
    name: "common-business-license___en-ca",
    path: "/en-ca/common/business-license",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/business-license.vue")
  },
  {
    name: "common-business-license___ja-jp",
    path: "/ja-jp/common/business-license",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/business-license.vue")
  },
  {
    name: "common-customer-callservice___zh-cn",
    path: "/zh-cn/common/customer/callservice",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/callservice.vue")
  },
  {
    name: "common-customer-callservice___zh-hk",
    path: "/zh-hk/common/customer/callservice",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/callservice.vue")
  },
  {
    name: "common-customer-callservice___zh-tw",
    path: "/zh-tw/common/customer/callservice",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/callservice.vue")
  },
  {
    name: "common-customer-callservice___en-us",
    path: "/en-us/common/customer/callservice",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/callservice.vue")
  },
  {
    name: "common-customer-callservice___en-xx___default",
    path: "/common/customer/callservice",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/callservice.vue")
  },
  {
    name: "common-customer-callservice___en-xx",
    path: "/en-xx/common/customer/callservice",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/callservice.vue")
  },
  {
    name: "common-customer-callservice___en-gb",
    path: "/en-gb/common/customer/callservice",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/callservice.vue")
  },
  {
    name: "common-customer-callservice___en-hk",
    path: "/en-hk/common/customer/callservice",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/callservice.vue")
  },
  {
    name: "common-customer-callservice___de-de",
    path: "/de-de/common/customer/callservice",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/callservice.vue")
  },
  {
    name: "common-customer-callservice___en-sg",
    path: "/en-sg/common/customer/callservice",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/callservice.vue")
  },
  {
    name: "common-customer-callservice___en-au",
    path: "/en-au/common/customer/callservice",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/callservice.vue")
  },
  {
    name: "common-customer-callservice___en-my",
    path: "/en-my/common/customer/callservice",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/callservice.vue")
  },
  {
    name: "common-customer-callservice___en-ph",
    path: "/en-ph/common/customer/callservice",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/callservice.vue")
  },
  {
    name: "common-customer-callservice___en-nz",
    path: "/en-nz/common/customer/callservice",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/callservice.vue")
  },
  {
    name: "common-customer-callservice___en-ca",
    path: "/en-ca/common/customer/callservice",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/callservice.vue")
  },
  {
    name: "common-customer-callservice___ja-jp",
    path: "/ja-jp/common/customer/callservice",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/callservice.vue")
  },
  {
    name: "common-customer___zh-cn",
    path: "/zh-cn/common/customer",
    meta: indexVyD9HzvOqeMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/index.vue")
  },
  {
    name: "common-customer___zh-hk",
    path: "/zh-hk/common/customer",
    meta: indexVyD9HzvOqeMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/index.vue")
  },
  {
    name: "common-customer___zh-tw",
    path: "/zh-tw/common/customer",
    meta: indexVyD9HzvOqeMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/index.vue")
  },
  {
    name: "common-customer___en-us",
    path: "/en-us/common/customer",
    meta: indexVyD9HzvOqeMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/index.vue")
  },
  {
    name: "common-customer___en-xx___default",
    path: "/common/customer",
    meta: indexVyD9HzvOqeMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/index.vue")
  },
  {
    name: "common-customer___en-xx",
    path: "/en-xx/common/customer",
    meta: indexVyD9HzvOqeMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/index.vue")
  },
  {
    name: "common-customer___en-gb",
    path: "/en-gb/common/customer",
    meta: indexVyD9HzvOqeMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/index.vue")
  },
  {
    name: "common-customer___en-hk",
    path: "/en-hk/common/customer",
    meta: indexVyD9HzvOqeMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/index.vue")
  },
  {
    name: "common-customer___de-de",
    path: "/de-de/common/customer",
    meta: indexVyD9HzvOqeMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/index.vue")
  },
  {
    name: "common-customer___en-sg",
    path: "/en-sg/common/customer",
    meta: indexVyD9HzvOqeMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/index.vue")
  },
  {
    name: "common-customer___en-au",
    path: "/en-au/common/customer",
    meta: indexVyD9HzvOqeMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/index.vue")
  },
  {
    name: "common-customer___en-my",
    path: "/en-my/common/customer",
    meta: indexVyD9HzvOqeMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/index.vue")
  },
  {
    name: "common-customer___en-ph",
    path: "/en-ph/common/customer",
    meta: indexVyD9HzvOqeMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/index.vue")
  },
  {
    name: "common-customer___en-nz",
    path: "/en-nz/common/customer",
    meta: indexVyD9HzvOqeMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/index.vue")
  },
  {
    name: "common-customer___en-ca",
    path: "/en-ca/common/customer",
    meta: indexVyD9HzvOqeMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/index.vue")
  },
  {
    name: "common-customer___ja-jp",
    path: "/ja-jp/common/customer",
    meta: indexVyD9HzvOqeMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/customer/index.vue")
  },
  {
    name: "common-email-us___zh-cn",
    path: "/zh-cn/common/email-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/email-us.vue")
  },
  {
    name: "common-email-us___zh-hk",
    path: "/zh-hk/common/email-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/email-us.vue")
  },
  {
    name: "common-email-us___zh-tw",
    path: "/zh-tw/common/email-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/email-us.vue")
  },
  {
    name: "common-email-us___en-us",
    path: "/en-us/common/email-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/email-us.vue")
  },
  {
    name: "common-email-us___en-xx___default",
    path: "/common/email-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/email-us.vue")
  },
  {
    name: "common-email-us___en-xx",
    path: "/en-xx/common/email-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/email-us.vue")
  },
  {
    name: "common-email-us___en-gb",
    path: "/en-gb/common/email-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/email-us.vue")
  },
  {
    name: "common-email-us___en-hk",
    path: "/en-hk/common/email-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/email-us.vue")
  },
  {
    name: "common-email-us___de-de",
    path: "/de-de/common/email-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/email-us.vue")
  },
  {
    name: "common-email-us___en-sg",
    path: "/en-sg/common/email-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/email-us.vue")
  },
  {
    name: "common-email-us___en-au",
    path: "/en-au/common/email-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/email-us.vue")
  },
  {
    name: "common-email-us___en-my",
    path: "/en-my/common/email-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/email-us.vue")
  },
  {
    name: "common-email-us___en-ph",
    path: "/en-ph/common/email-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/email-us.vue")
  },
  {
    name: "common-email-us___en-nz",
    path: "/en-nz/common/email-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/email-us.vue")
  },
  {
    name: "common-email-us___en-ca",
    path: "/en-ca/common/email-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/email-us.vue")
  },
  {
    name: "common-email-us___ja-jp",
    path: "/ja-jp/common/email-us",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/email-us.vue")
  },
  {
    name: "common-faq___zh-cn",
    path: "/zh-cn/common/faq",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/faq.vue")
  },
  {
    name: "common-faq___zh-hk",
    path: "/zh-hk/common/faq",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/faq.vue")
  },
  {
    name: "common-faq___zh-tw",
    path: "/zh-tw/common/faq",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/faq.vue")
  },
  {
    name: "common-faq___en-us",
    path: "/en-us/common/faq",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/faq.vue")
  },
  {
    name: "common-faq___en-xx___default",
    path: "/common/faq",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/faq.vue")
  },
  {
    name: "common-faq___en-xx",
    path: "/en-xx/common/faq",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/faq.vue")
  },
  {
    name: "common-faq___en-gb",
    path: "/en-gb/common/faq",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/faq.vue")
  },
  {
    name: "common-faq___en-hk",
    path: "/en-hk/common/faq",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/faq.vue")
  },
  {
    name: "common-faq___de-de",
    path: "/de-de/common/faq",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/faq.vue")
  },
  {
    name: "common-faq___en-sg",
    path: "/en-sg/common/faq",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/faq.vue")
  },
  {
    name: "common-faq___en-au",
    path: "/en-au/common/faq",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/faq.vue")
  },
  {
    name: "common-faq___en-my",
    path: "/en-my/common/faq",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/faq.vue")
  },
  {
    name: "common-faq___en-ph",
    path: "/en-ph/common/faq",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/faq.vue")
  },
  {
    name: "common-faq___en-nz",
    path: "/en-nz/common/faq",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/faq.vue")
  },
  {
    name: "common-faq___en-ca",
    path: "/en-ca/common/faq",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/faq.vue")
  },
  {
    name: "common-faq___ja-jp",
    path: "/ja-jp/common/faq",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/faq.vue")
  },
  {
    name: "common-feedback___zh-cn",
    path: "/zh-cn/common/feedback",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/feedback.vue")
  },
  {
    name: "common-feedback___zh-hk",
    path: "/zh-hk/common/feedback",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/feedback.vue")
  },
  {
    name: "common-feedback___zh-tw",
    path: "/zh-tw/common/feedback",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/feedback.vue")
  },
  {
    name: "common-feedback___en-us",
    path: "/en-us/common/feedback",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/feedback.vue")
  },
  {
    name: "common-feedback___en-xx___default",
    path: "/common/feedback",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/feedback.vue")
  },
  {
    name: "common-feedback___en-xx",
    path: "/en-xx/common/feedback",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/feedback.vue")
  },
  {
    name: "common-feedback___en-gb",
    path: "/en-gb/common/feedback",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/feedback.vue")
  },
  {
    name: "common-feedback___en-hk",
    path: "/en-hk/common/feedback",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/feedback.vue")
  },
  {
    name: "common-feedback___de-de",
    path: "/de-de/common/feedback",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/feedback.vue")
  },
  {
    name: "common-feedback___en-sg",
    path: "/en-sg/common/feedback",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/feedback.vue")
  },
  {
    name: "common-feedback___en-au",
    path: "/en-au/common/feedback",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/feedback.vue")
  },
  {
    name: "common-feedback___en-my",
    path: "/en-my/common/feedback",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/feedback.vue")
  },
  {
    name: "common-feedback___en-ph",
    path: "/en-ph/common/feedback",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/feedback.vue")
  },
  {
    name: "common-feedback___en-nz",
    path: "/en-nz/common/feedback",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/feedback.vue")
  },
  {
    name: "common-feedback___en-ca",
    path: "/en-ca/common/feedback",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/feedback.vue")
  },
  {
    name: "common-feedback___ja-jp",
    path: "/ja-jp/common/feedback",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/feedback.vue")
  },
  {
    name: "common-privacy-policy___zh-cn",
    path: "/zh-cn/common/privacy-policy",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/privacy-policy.vue")
  },
  {
    name: "common-privacy-policy___zh-hk",
    path: "/zh-hk/common/privacy-policy",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/privacy-policy.vue")
  },
  {
    name: "common-privacy-policy___zh-tw",
    path: "/zh-tw/common/privacy-policy",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/privacy-policy.vue")
  },
  {
    name: "common-privacy-policy___en-us",
    path: "/en-us/common/privacy-policy",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/privacy-policy.vue")
  },
  {
    name: "common-privacy-policy___en-xx___default",
    path: "/common/privacy-policy",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/privacy-policy.vue")
  },
  {
    name: "common-privacy-policy___en-xx",
    path: "/en-xx/common/privacy-policy",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/privacy-policy.vue")
  },
  {
    name: "common-privacy-policy___en-gb",
    path: "/en-gb/common/privacy-policy",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/privacy-policy.vue")
  },
  {
    name: "common-privacy-policy___en-hk",
    path: "/en-hk/common/privacy-policy",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/privacy-policy.vue")
  },
  {
    name: "common-privacy-policy___de-de",
    path: "/de-de/common/privacy-policy",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/privacy-policy.vue")
  },
  {
    name: "common-privacy-policy___en-sg",
    path: "/en-sg/common/privacy-policy",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/privacy-policy.vue")
  },
  {
    name: "common-privacy-policy___en-au",
    path: "/en-au/common/privacy-policy",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/privacy-policy.vue")
  },
  {
    name: "common-privacy-policy___en-my",
    path: "/en-my/common/privacy-policy",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/privacy-policy.vue")
  },
  {
    name: "common-privacy-policy___en-ph",
    path: "/en-ph/common/privacy-policy",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/privacy-policy.vue")
  },
  {
    name: "common-privacy-policy___en-nz",
    path: "/en-nz/common/privacy-policy",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/privacy-policy.vue")
  },
  {
    name: "common-privacy-policy___en-ca",
    path: "/en-ca/common/privacy-policy",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/privacy-policy.vue")
  },
  {
    name: "common-privacy-policy___ja-jp",
    path: "/ja-jp/common/privacy-policy",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/privacy-policy.vue")
  },
  {
    name: "common-submit-question___zh-cn",
    path: "/zh-cn/common/submit-question",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/submit-question.vue")
  },
  {
    name: "common-submit-question___zh-hk",
    path: "/zh-hk/common/submit-question",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/submit-question.vue")
  },
  {
    name: "common-submit-question___zh-tw",
    path: "/zh-tw/common/submit-question",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/submit-question.vue")
  },
  {
    name: "common-submit-question___en-us",
    path: "/en-us/common/submit-question",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/submit-question.vue")
  },
  {
    name: "common-submit-question___en-xx___default",
    path: "/common/submit-question",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/submit-question.vue")
  },
  {
    name: "common-submit-question___en-xx",
    path: "/en-xx/common/submit-question",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/submit-question.vue")
  },
  {
    name: "common-submit-question___en-gb",
    path: "/en-gb/common/submit-question",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/submit-question.vue")
  },
  {
    name: "common-submit-question___en-hk",
    path: "/en-hk/common/submit-question",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/submit-question.vue")
  },
  {
    name: "common-submit-question___de-de",
    path: "/de-de/common/submit-question",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/submit-question.vue")
  },
  {
    name: "common-submit-question___en-sg",
    path: "/en-sg/common/submit-question",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/submit-question.vue")
  },
  {
    name: "common-submit-question___en-au",
    path: "/en-au/common/submit-question",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/submit-question.vue")
  },
  {
    name: "common-submit-question___en-my",
    path: "/en-my/common/submit-question",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/submit-question.vue")
  },
  {
    name: "common-submit-question___en-ph",
    path: "/en-ph/common/submit-question",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/submit-question.vue")
  },
  {
    name: "common-submit-question___en-nz",
    path: "/en-nz/common/submit-question",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/submit-question.vue")
  },
  {
    name: "common-submit-question___en-ca",
    path: "/en-ca/common/submit-question",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/submit-question.vue")
  },
  {
    name: "common-submit-question___ja-jp",
    path: "/ja-jp/common/submit-question",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/submit-question.vue")
  },
  {
    name: "common-subscription___zh-cn",
    path: "/zh-cn/common/subscription",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/subscription.vue")
  },
  {
    name: "common-subscription___zh-hk",
    path: "/zh-hk/common/subscription",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/subscription.vue")
  },
  {
    name: "common-subscription___zh-tw",
    path: "/zh-tw/common/subscription",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/subscription.vue")
  },
  {
    name: "common-subscription___en-us",
    path: "/en-us/common/subscription",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/subscription.vue")
  },
  {
    name: "common-subscription___en-xx___default",
    path: "/common/subscription",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/subscription.vue")
  },
  {
    name: "common-subscription___en-xx",
    path: "/en-xx/common/subscription",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/subscription.vue")
  },
  {
    name: "common-subscription___en-gb",
    path: "/en-gb/common/subscription",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/subscription.vue")
  },
  {
    name: "common-subscription___en-hk",
    path: "/en-hk/common/subscription",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/subscription.vue")
  },
  {
    name: "common-subscription___de-de",
    path: "/de-de/common/subscription",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/subscription.vue")
  },
  {
    name: "common-subscription___en-sg",
    path: "/en-sg/common/subscription",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/subscription.vue")
  },
  {
    name: "common-subscription___en-au",
    path: "/en-au/common/subscription",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/subscription.vue")
  },
  {
    name: "common-subscription___en-my",
    path: "/en-my/common/subscription",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/subscription.vue")
  },
  {
    name: "common-subscription___en-ph",
    path: "/en-ph/common/subscription",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/subscription.vue")
  },
  {
    name: "common-subscription___en-nz",
    path: "/en-nz/common/subscription",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/subscription.vue")
  },
  {
    name: "common-subscription___en-ca",
    path: "/en-ca/common/subscription",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/subscription.vue")
  },
  {
    name: "common-subscription___ja-jp",
    path: "/ja-jp/common/subscription",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/subscription.vue")
  },
  {
    name: "common-terms-and-conditions___zh-cn",
    path: "/zh-cn/common/terms-and-conditions",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/terms-and-conditions.vue")
  },
  {
    name: "common-terms-and-conditions___zh-hk",
    path: "/zh-hk/common/terms-and-conditions",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/terms-and-conditions.vue")
  },
  {
    name: "common-terms-and-conditions___zh-tw",
    path: "/zh-tw/common/terms-and-conditions",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/terms-and-conditions.vue")
  },
  {
    name: "common-terms-and-conditions___en-us",
    path: "/en-us/common/terms-and-conditions",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/terms-and-conditions.vue")
  },
  {
    name: "common-terms-and-conditions___en-xx___default",
    path: "/common/terms-and-conditions",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/terms-and-conditions.vue")
  },
  {
    name: "common-terms-and-conditions___en-xx",
    path: "/en-xx/common/terms-and-conditions",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/terms-and-conditions.vue")
  },
  {
    name: "common-terms-and-conditions___en-gb",
    path: "/en-gb/common/terms-and-conditions",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/terms-and-conditions.vue")
  },
  {
    name: "common-terms-and-conditions___en-hk",
    path: "/en-hk/common/terms-and-conditions",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/terms-and-conditions.vue")
  },
  {
    name: "common-terms-and-conditions___de-de",
    path: "/de-de/common/terms-and-conditions",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/terms-and-conditions.vue")
  },
  {
    name: "common-terms-and-conditions___en-sg",
    path: "/en-sg/common/terms-and-conditions",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/terms-and-conditions.vue")
  },
  {
    name: "common-terms-and-conditions___en-au",
    path: "/en-au/common/terms-and-conditions",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/terms-and-conditions.vue")
  },
  {
    name: "common-terms-and-conditions___en-my",
    path: "/en-my/common/terms-and-conditions",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/terms-and-conditions.vue")
  },
  {
    name: "common-terms-and-conditions___en-ph",
    path: "/en-ph/common/terms-and-conditions",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/terms-and-conditions.vue")
  },
  {
    name: "common-terms-and-conditions___en-nz",
    path: "/en-nz/common/terms-and-conditions",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/terms-and-conditions.vue")
  },
  {
    name: "common-terms-and-conditions___en-ca",
    path: "/en-ca/common/terms-and-conditions",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/terms-and-conditions.vue")
  },
  {
    name: "common-terms-and-conditions___ja-jp",
    path: "/ja-jp/common/terms-and-conditions",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/terms-and-conditions.vue")
  },
  {
    name: "common-unsubscribe___zh-cn",
    path: "/zh-cn/common/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/unsubscribe.vue")
  },
  {
    name: "common-unsubscribe___zh-hk",
    path: "/zh-hk/common/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/unsubscribe.vue")
  },
  {
    name: "common-unsubscribe___zh-tw",
    path: "/zh-tw/common/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/unsubscribe.vue")
  },
  {
    name: "common-unsubscribe___en-us",
    path: "/en-us/common/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/unsubscribe.vue")
  },
  {
    name: "common-unsubscribe___en-xx___default",
    path: "/common/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/unsubscribe.vue")
  },
  {
    name: "common-unsubscribe___en-xx",
    path: "/en-xx/common/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/unsubscribe.vue")
  },
  {
    name: "common-unsubscribe___en-gb",
    path: "/en-gb/common/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/unsubscribe.vue")
  },
  {
    name: "common-unsubscribe___en-hk",
    path: "/en-hk/common/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/unsubscribe.vue")
  },
  {
    name: "common-unsubscribe___de-de",
    path: "/de-de/common/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/unsubscribe.vue")
  },
  {
    name: "common-unsubscribe___en-sg",
    path: "/en-sg/common/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/unsubscribe.vue")
  },
  {
    name: "common-unsubscribe___en-au",
    path: "/en-au/common/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/unsubscribe.vue")
  },
  {
    name: "common-unsubscribe___en-my",
    path: "/en-my/common/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/unsubscribe.vue")
  },
  {
    name: "common-unsubscribe___en-ph",
    path: "/en-ph/common/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/unsubscribe.vue")
  },
  {
    name: "common-unsubscribe___en-nz",
    path: "/en-nz/common/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/unsubscribe.vue")
  },
  {
    name: "common-unsubscribe___en-ca",
    path: "/en-ca/common/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/unsubscribe.vue")
  },
  {
    name: "common-unsubscribe___ja-jp",
    path: "/ja-jp/common/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/common/unsubscribe.vue")
  },
  {
    name: "coupon___zh-cn",
    path: "/zh-cn/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coupon/index.vue")
  },
  {
    name: "coupon___zh-hk",
    path: "/zh-hk/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coupon/index.vue")
  },
  {
    name: "coupon___zh-tw",
    path: "/zh-tw/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coupon/index.vue")
  },
  {
    name: "coupon___en-us",
    path: "/en-us/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coupon/index.vue")
  },
  {
    name: "coupon___en-xx___default",
    path: "/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coupon/index.vue")
  },
  {
    name: "coupon___en-xx",
    path: "/en-xx/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coupon/index.vue")
  },
  {
    name: "coupon___en-gb",
    path: "/en-gb/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coupon/index.vue")
  },
  {
    name: "coupon___en-hk",
    path: "/en-hk/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coupon/index.vue")
  },
  {
    name: "coupon___de-de",
    path: "/de-de/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coupon/index.vue")
  },
  {
    name: "coupon___en-sg",
    path: "/en-sg/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coupon/index.vue")
  },
  {
    name: "coupon___en-au",
    path: "/en-au/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coupon/index.vue")
  },
  {
    name: "coupon___en-my",
    path: "/en-my/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coupon/index.vue")
  },
  {
    name: "coupon___en-ph",
    path: "/en-ph/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coupon/index.vue")
  },
  {
    name: "coupon___en-nz",
    path: "/en-nz/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coupon/index.vue")
  },
  {
    name: "coupon___en-ca",
    path: "/en-ca/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coupon/index.vue")
  },
  {
    name: "coupon___ja-jp",
    path: "/ja-jp/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/coupon/index.vue")
  },
  {
    name: "index___zh-cn",
    path: "/zh-cn",
    meta: index7OH1QyEmn7Meta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/index.vue")
  },
  {
    name: "index___zh-hk",
    path: "/zh-hk",
    meta: index7OH1QyEmn7Meta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/index.vue")
  },
  {
    name: "index___zh-tw",
    path: "/zh-tw",
    meta: index7OH1QyEmn7Meta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/index.vue")
  },
  {
    name: "index___en-us",
    path: "/en-us",
    meta: index7OH1QyEmn7Meta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/index.vue")
  },
  {
    name: "index___en-xx___default",
    path: "/",
    meta: index7OH1QyEmn7Meta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/index.vue")
  },
  {
    name: "index___en-xx",
    path: "/en-xx",
    meta: index7OH1QyEmn7Meta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/index.vue")
  },
  {
    name: "index___en-gb",
    path: "/en-gb",
    meta: index7OH1QyEmn7Meta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/index.vue")
  },
  {
    name: "index___en-hk",
    path: "/en-hk",
    meta: index7OH1QyEmn7Meta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/index.vue")
  },
  {
    name: "index___de-de",
    path: "/de-de",
    meta: index7OH1QyEmn7Meta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/index.vue")
  },
  {
    name: "index___en-sg",
    path: "/en-sg",
    meta: index7OH1QyEmn7Meta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/index.vue")
  },
  {
    name: "index___en-au",
    path: "/en-au",
    meta: index7OH1QyEmn7Meta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/index.vue")
  },
  {
    name: "index___en-my",
    path: "/en-my",
    meta: index7OH1QyEmn7Meta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/index.vue")
  },
  {
    name: "index___en-ph",
    path: "/en-ph",
    meta: index7OH1QyEmn7Meta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/index.vue")
  },
  {
    name: "index___en-nz",
    path: "/en-nz",
    meta: index7OH1QyEmn7Meta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/index.vue")
  },
  {
    name: "index___en-ca",
    path: "/en-ca",
    meta: index7OH1QyEmn7Meta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/index.vue")
  },
  {
    name: "index___ja-jp",
    path: "/ja-jp",
    meta: index7OH1QyEmn7Meta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/index.vue")
  },
  {
    name: "inquiry-fill___zh-cn",
    path: "/zh-cn/inquiry/fill",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/fill.vue")
  },
  {
    name: "inquiry-fill___zh-hk",
    path: "/zh-hk/inquiry/fill",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/fill.vue")
  },
  {
    name: "inquiry-fill___zh-tw",
    path: "/zh-tw/inquiry/fill",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/fill.vue")
  },
  {
    name: "inquiry-fill___en-us",
    path: "/en-us/inquiry/fill",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/fill.vue")
  },
  {
    name: "inquiry-fill___en-xx___default",
    path: "/inquiry/fill",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/fill.vue")
  },
  {
    name: "inquiry-fill___en-xx",
    path: "/en-xx/inquiry/fill",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/fill.vue")
  },
  {
    name: "inquiry-fill___en-gb",
    path: "/en-gb/inquiry/fill",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/fill.vue")
  },
  {
    name: "inquiry-fill___en-hk",
    path: "/en-hk/inquiry/fill",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/fill.vue")
  },
  {
    name: "inquiry-fill___de-de",
    path: "/de-de/inquiry/fill",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/fill.vue")
  },
  {
    name: "inquiry-fill___en-sg",
    path: "/en-sg/inquiry/fill",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/fill.vue")
  },
  {
    name: "inquiry-fill___en-au",
    path: "/en-au/inquiry/fill",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/fill.vue")
  },
  {
    name: "inquiry-fill___en-my",
    path: "/en-my/inquiry/fill",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/fill.vue")
  },
  {
    name: "inquiry-fill___en-ph",
    path: "/en-ph/inquiry/fill",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/fill.vue")
  },
  {
    name: "inquiry-fill___en-nz",
    path: "/en-nz/inquiry/fill",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/fill.vue")
  },
  {
    name: "inquiry-fill___en-ca",
    path: "/en-ca/inquiry/fill",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/fill.vue")
  },
  {
    name: "inquiry-fill___ja-jp",
    path: "/ja-jp/inquiry/fill",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/fill.vue")
  },
  {
    name: "inquiry-list___zh-cn",
    path: "/zh-cn/inquiry/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/list.vue")
  },
  {
    name: "inquiry-list___zh-hk",
    path: "/zh-hk/inquiry/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/list.vue")
  },
  {
    name: "inquiry-list___zh-tw",
    path: "/zh-tw/inquiry/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/list.vue")
  },
  {
    name: "inquiry-list___en-us",
    path: "/en-us/inquiry/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/list.vue")
  },
  {
    name: "inquiry-list___en-xx___default",
    path: "/inquiry/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/list.vue")
  },
  {
    name: "inquiry-list___en-xx",
    path: "/en-xx/inquiry/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/list.vue")
  },
  {
    name: "inquiry-list___en-gb",
    path: "/en-gb/inquiry/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/list.vue")
  },
  {
    name: "inquiry-list___en-hk",
    path: "/en-hk/inquiry/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/list.vue")
  },
  {
    name: "inquiry-list___de-de",
    path: "/de-de/inquiry/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/list.vue")
  },
  {
    name: "inquiry-list___en-sg",
    path: "/en-sg/inquiry/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/list.vue")
  },
  {
    name: "inquiry-list___en-au",
    path: "/en-au/inquiry/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/list.vue")
  },
  {
    name: "inquiry-list___en-my",
    path: "/en-my/inquiry/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/list.vue")
  },
  {
    name: "inquiry-list___en-ph",
    path: "/en-ph/inquiry/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/list.vue")
  },
  {
    name: "inquiry-list___en-nz",
    path: "/en-nz/inquiry/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/list.vue")
  },
  {
    name: "inquiry-list___en-ca",
    path: "/en-ca/inquiry/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/list.vue")
  },
  {
    name: "inquiry-list___ja-jp",
    path: "/ja-jp/inquiry/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/inquiry/list.vue")
  },
  {
    name: "keepalive___zh-cn",
    path: "/zh-cn/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/keepalive/index.vue")
  },
  {
    name: "keepalive___zh-hk",
    path: "/zh-hk/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/keepalive/index.vue")
  },
  {
    name: "keepalive___zh-tw",
    path: "/zh-tw/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/keepalive/index.vue")
  },
  {
    name: "keepalive___en-us",
    path: "/en-us/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/keepalive/index.vue")
  },
  {
    name: "keepalive___en-xx___default",
    path: "/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/keepalive/index.vue")
  },
  {
    name: "keepalive___en-xx",
    path: "/en-xx/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/keepalive/index.vue")
  },
  {
    name: "keepalive___en-gb",
    path: "/en-gb/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/keepalive/index.vue")
  },
  {
    name: "keepalive___en-hk",
    path: "/en-hk/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/keepalive/index.vue")
  },
  {
    name: "keepalive___de-de",
    path: "/de-de/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/keepalive/index.vue")
  },
  {
    name: "keepalive___en-sg",
    path: "/en-sg/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/keepalive/index.vue")
  },
  {
    name: "keepalive___en-au",
    path: "/en-au/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/keepalive/index.vue")
  },
  {
    name: "keepalive___en-my",
    path: "/en-my/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/keepalive/index.vue")
  },
  {
    name: "keepalive___en-ph",
    path: "/en-ph/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/keepalive/index.vue")
  },
  {
    name: "keepalive___en-nz",
    path: "/en-nz/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/keepalive/index.vue")
  },
  {
    name: "keepalive___en-ca",
    path: "/en-ca/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/keepalive/index.vue")
  },
  {
    name: "keepalive___ja-jp",
    path: "/ja-jp/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/keepalive/index.vue")
  },
  {
    name: "main-flights___zh-cn",
    path: "/zh-cn/main-flights",
    meta: main_45flightsOg7UIS7lMKMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-flights.vue")
  },
  {
    name: "main-flights___zh-hk",
    path: "/zh-hk/main-flights",
    meta: main_45flightsOg7UIS7lMKMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-flights.vue")
  },
  {
    name: "main-flights___zh-tw",
    path: "/zh-tw/main-flights",
    meta: main_45flightsOg7UIS7lMKMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-flights.vue")
  },
  {
    name: "main-flights___en-us",
    path: "/en-us/main-flights",
    meta: main_45flightsOg7UIS7lMKMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-flights.vue")
  },
  {
    name: "main-flights___en-xx___default",
    path: "/main-flights",
    meta: main_45flightsOg7UIS7lMKMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-flights.vue")
  },
  {
    name: "main-flights___en-xx",
    path: "/en-xx/main-flights",
    meta: main_45flightsOg7UIS7lMKMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-flights.vue")
  },
  {
    name: "main-flights___en-gb",
    path: "/en-gb/main-flights",
    meta: main_45flightsOg7UIS7lMKMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-flights.vue")
  },
  {
    name: "main-flights___en-hk",
    path: "/en-hk/main-flights",
    meta: main_45flightsOg7UIS7lMKMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-flights.vue")
  },
  {
    name: "main-flights___de-de",
    path: "/de-de/main-flights",
    meta: main_45flightsOg7UIS7lMKMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-flights.vue")
  },
  {
    name: "main-flights___en-sg",
    path: "/en-sg/main-flights",
    meta: main_45flightsOg7UIS7lMKMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-flights.vue")
  },
  {
    name: "main-flights___en-au",
    path: "/en-au/main-flights",
    meta: main_45flightsOg7UIS7lMKMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-flights.vue")
  },
  {
    name: "main-flights___en-my",
    path: "/en-my/main-flights",
    meta: main_45flightsOg7UIS7lMKMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-flights.vue")
  },
  {
    name: "main-flights___en-ph",
    path: "/en-ph/main-flights",
    meta: main_45flightsOg7UIS7lMKMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-flights.vue")
  },
  {
    name: "main-flights___en-nz",
    path: "/en-nz/main-flights",
    meta: main_45flightsOg7UIS7lMKMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-flights.vue")
  },
  {
    name: "main-flights___en-ca",
    path: "/en-ca/main-flights",
    meta: main_45flightsOg7UIS7lMKMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-flights.vue")
  },
  {
    name: "main-flights___ja-jp",
    path: "/ja-jp/main-flights",
    meta: main_45flightsOg7UIS7lMKMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-flights.vue")
  },
  {
    name: "main-hotels___zh-cn",
    path: "/zh-cn/main-hotels",
    meta: main_45hotels5VNrZ22TGHMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-hotels.vue")
  },
  {
    name: "main-hotels___zh-hk",
    path: "/zh-hk/main-hotels",
    meta: main_45hotels5VNrZ22TGHMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-hotels.vue")
  },
  {
    name: "main-hotels___zh-tw",
    path: "/zh-tw/main-hotels",
    meta: main_45hotels5VNrZ22TGHMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-hotels.vue")
  },
  {
    name: "main-hotels___en-us",
    path: "/en-us/main-hotels",
    meta: main_45hotels5VNrZ22TGHMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-hotels.vue")
  },
  {
    name: "main-hotels___en-xx___default",
    path: "/main-hotels",
    meta: main_45hotels5VNrZ22TGHMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-hotels.vue")
  },
  {
    name: "main-hotels___en-xx",
    path: "/en-xx/main-hotels",
    meta: main_45hotels5VNrZ22TGHMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-hotels.vue")
  },
  {
    name: "main-hotels___en-gb",
    path: "/en-gb/main-hotels",
    meta: main_45hotels5VNrZ22TGHMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-hotels.vue")
  },
  {
    name: "main-hotels___en-hk",
    path: "/en-hk/main-hotels",
    meta: main_45hotels5VNrZ22TGHMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-hotels.vue")
  },
  {
    name: "main-hotels___de-de",
    path: "/de-de/main-hotels",
    meta: main_45hotels5VNrZ22TGHMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-hotels.vue")
  },
  {
    name: "main-hotels___en-sg",
    path: "/en-sg/main-hotels",
    meta: main_45hotels5VNrZ22TGHMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-hotels.vue")
  },
  {
    name: "main-hotels___en-au",
    path: "/en-au/main-hotels",
    meta: main_45hotels5VNrZ22TGHMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-hotels.vue")
  },
  {
    name: "main-hotels___en-my",
    path: "/en-my/main-hotels",
    meta: main_45hotels5VNrZ22TGHMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-hotels.vue")
  },
  {
    name: "main-hotels___en-ph",
    path: "/en-ph/main-hotels",
    meta: main_45hotels5VNrZ22TGHMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-hotels.vue")
  },
  {
    name: "main-hotels___en-nz",
    path: "/en-nz/main-hotels",
    meta: main_45hotels5VNrZ22TGHMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-hotels.vue")
  },
  {
    name: "main-hotels___en-ca",
    path: "/en-ca/main-hotels",
    meta: main_45hotels5VNrZ22TGHMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-hotels.vue")
  },
  {
    name: "main-hotels___ja-jp",
    path: "/ja-jp/main-hotels",
    meta: main_45hotels5VNrZ22TGHMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-hotels.vue")
  },
  {
    name: "main-trains___zh-cn",
    path: "/zh-cn/main-trains",
    meta: main_45trainsuWxqKmPZoJMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-trains.vue")
  },
  {
    name: "main-trains___zh-hk",
    path: "/zh-hk/main-trains",
    meta: main_45trainsuWxqKmPZoJMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-trains.vue")
  },
  {
    name: "main-trains___zh-tw",
    path: "/zh-tw/main-trains",
    meta: main_45trainsuWxqKmPZoJMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-trains.vue")
  },
  {
    name: "main-trains___en-us",
    path: "/en-us/main-trains",
    meta: main_45trainsuWxqKmPZoJMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-trains.vue")
  },
  {
    name: "main-trains___en-xx___default",
    path: "/main-trains",
    meta: main_45trainsuWxqKmPZoJMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-trains.vue")
  },
  {
    name: "main-trains___en-xx",
    path: "/en-xx/main-trains",
    meta: main_45trainsuWxqKmPZoJMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-trains.vue")
  },
  {
    name: "main-trains___en-gb",
    path: "/en-gb/main-trains",
    meta: main_45trainsuWxqKmPZoJMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-trains.vue")
  },
  {
    name: "main-trains___en-hk",
    path: "/en-hk/main-trains",
    meta: main_45trainsuWxqKmPZoJMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-trains.vue")
  },
  {
    name: "main-trains___de-de",
    path: "/de-de/main-trains",
    meta: main_45trainsuWxqKmPZoJMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-trains.vue")
  },
  {
    name: "main-trains___en-sg",
    path: "/en-sg/main-trains",
    meta: main_45trainsuWxqKmPZoJMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-trains.vue")
  },
  {
    name: "main-trains___en-au",
    path: "/en-au/main-trains",
    meta: main_45trainsuWxqKmPZoJMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-trains.vue")
  },
  {
    name: "main-trains___en-my",
    path: "/en-my/main-trains",
    meta: main_45trainsuWxqKmPZoJMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-trains.vue")
  },
  {
    name: "main-trains___en-ph",
    path: "/en-ph/main-trains",
    meta: main_45trainsuWxqKmPZoJMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-trains.vue")
  },
  {
    name: "main-trains___en-nz",
    path: "/en-nz/main-trains",
    meta: main_45trainsuWxqKmPZoJMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-trains.vue")
  },
  {
    name: "main-trains___en-ca",
    path: "/en-ca/main-trains",
    meta: main_45trainsuWxqKmPZoJMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-trains.vue")
  },
  {
    name: "main-trains___ja-jp",
    path: "/ja-jp/main-trains",
    meta: main_45trainsuWxqKmPZoJMeta || {},
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/main-trains.vue")
  },
  {
    name: "member-changepassword___zh-cn",
    path: "/zh-cn/member/changepassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/changepassword.vue")
  },
  {
    name: "member-changepassword___zh-hk",
    path: "/zh-hk/member/changepassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/changepassword.vue")
  },
  {
    name: "member-changepassword___zh-tw",
    path: "/zh-tw/member/changepassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/changepassword.vue")
  },
  {
    name: "member-changepassword___en-us",
    path: "/en-us/member/changepassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/changepassword.vue")
  },
  {
    name: "member-changepassword___en-xx___default",
    path: "/member/changepassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/changepassword.vue")
  },
  {
    name: "member-changepassword___en-xx",
    path: "/en-xx/member/changepassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/changepassword.vue")
  },
  {
    name: "member-changepassword___en-gb",
    path: "/en-gb/member/changepassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/changepassword.vue")
  },
  {
    name: "member-changepassword___en-hk",
    path: "/en-hk/member/changepassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/changepassword.vue")
  },
  {
    name: "member-changepassword___de-de",
    path: "/de-de/member/changepassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/changepassword.vue")
  },
  {
    name: "member-changepassword___en-sg",
    path: "/en-sg/member/changepassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/changepassword.vue")
  },
  {
    name: "member-changepassword___en-au",
    path: "/en-au/member/changepassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/changepassword.vue")
  },
  {
    name: "member-changepassword___en-my",
    path: "/en-my/member/changepassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/changepassword.vue")
  },
  {
    name: "member-changepassword___en-ph",
    path: "/en-ph/member/changepassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/changepassword.vue")
  },
  {
    name: "member-changepassword___en-nz",
    path: "/en-nz/member/changepassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/changepassword.vue")
  },
  {
    name: "member-changepassword___en-ca",
    path: "/en-ca/member/changepassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/changepassword.vue")
  },
  {
    name: "member-changepassword___ja-jp",
    path: "/ja-jp/member/changepassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/changepassword.vue")
  },
  {
    name: "member-collections___zh-cn",
    path: "/zh-cn/member/collections",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/collections.vue")
  },
  {
    name: "member-collections___zh-hk",
    path: "/zh-hk/member/collections",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/collections.vue")
  },
  {
    name: "member-collections___zh-tw",
    path: "/zh-tw/member/collections",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/collections.vue")
  },
  {
    name: "member-collections___en-us",
    path: "/en-us/member/collections",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/collections.vue")
  },
  {
    name: "member-collections___en-xx___default",
    path: "/member/collections",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/collections.vue")
  },
  {
    name: "member-collections___en-xx",
    path: "/en-xx/member/collections",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/collections.vue")
  },
  {
    name: "member-collections___en-gb",
    path: "/en-gb/member/collections",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/collections.vue")
  },
  {
    name: "member-collections___en-hk",
    path: "/en-hk/member/collections",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/collections.vue")
  },
  {
    name: "member-collections___de-de",
    path: "/de-de/member/collections",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/collections.vue")
  },
  {
    name: "member-collections___en-sg",
    path: "/en-sg/member/collections",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/collections.vue")
  },
  {
    name: "member-collections___en-au",
    path: "/en-au/member/collections",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/collections.vue")
  },
  {
    name: "member-collections___en-my",
    path: "/en-my/member/collections",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/collections.vue")
  },
  {
    name: "member-collections___en-ph",
    path: "/en-ph/member/collections",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/collections.vue")
  },
  {
    name: "member-collections___en-nz",
    path: "/en-nz/member/collections",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/collections.vue")
  },
  {
    name: "member-collections___en-ca",
    path: "/en-ca/member/collections",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/collections.vue")
  },
  {
    name: "member-collections___ja-jp",
    path: "/ja-jp/member/collections",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/collections.vue")
  },
  {
    name: "member-coupon___zh-cn",
    path: "/zh-cn/member/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/coupon.vue")
  },
  {
    name: "member-coupon___zh-hk",
    path: "/zh-hk/member/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/coupon.vue")
  },
  {
    name: "member-coupon___zh-tw",
    path: "/zh-tw/member/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/coupon.vue")
  },
  {
    name: "member-coupon___en-us",
    path: "/en-us/member/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/coupon.vue")
  },
  {
    name: "member-coupon___en-xx___default",
    path: "/member/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/coupon.vue")
  },
  {
    name: "member-coupon___en-xx",
    path: "/en-xx/member/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/coupon.vue")
  },
  {
    name: "member-coupon___en-gb",
    path: "/en-gb/member/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/coupon.vue")
  },
  {
    name: "member-coupon___en-hk",
    path: "/en-hk/member/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/coupon.vue")
  },
  {
    name: "member-coupon___de-de",
    path: "/de-de/member/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/coupon.vue")
  },
  {
    name: "member-coupon___en-sg",
    path: "/en-sg/member/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/coupon.vue")
  },
  {
    name: "member-coupon___en-au",
    path: "/en-au/member/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/coupon.vue")
  },
  {
    name: "member-coupon___en-my",
    path: "/en-my/member/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/coupon.vue")
  },
  {
    name: "member-coupon___en-ph",
    path: "/en-ph/member/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/coupon.vue")
  },
  {
    name: "member-coupon___en-nz",
    path: "/en-nz/member/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/coupon.vue")
  },
  {
    name: "member-coupon___en-ca",
    path: "/en-ca/member/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/coupon.vue")
  },
  {
    name: "member-coupon___ja-jp",
    path: "/ja-jp/member/coupon",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/coupon.vue")
  },
  {
    name: "member-deleteaccount___zh-cn",
    path: "/zh-cn/member/deleteaccount",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/deleteaccount.vue")
  },
  {
    name: "member-deleteaccount___zh-hk",
    path: "/zh-hk/member/deleteaccount",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/deleteaccount.vue")
  },
  {
    name: "member-deleteaccount___zh-tw",
    path: "/zh-tw/member/deleteaccount",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/deleteaccount.vue")
  },
  {
    name: "member-deleteaccount___en-us",
    path: "/en-us/member/deleteaccount",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/deleteaccount.vue")
  },
  {
    name: "member-deleteaccount___en-xx___default",
    path: "/member/deleteaccount",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/deleteaccount.vue")
  },
  {
    name: "member-deleteaccount___en-xx",
    path: "/en-xx/member/deleteaccount",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/deleteaccount.vue")
  },
  {
    name: "member-deleteaccount___en-gb",
    path: "/en-gb/member/deleteaccount",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/deleteaccount.vue")
  },
  {
    name: "member-deleteaccount___en-hk",
    path: "/en-hk/member/deleteaccount",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/deleteaccount.vue")
  },
  {
    name: "member-deleteaccount___de-de",
    path: "/de-de/member/deleteaccount",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/deleteaccount.vue")
  },
  {
    name: "member-deleteaccount___en-sg",
    path: "/en-sg/member/deleteaccount",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/deleteaccount.vue")
  },
  {
    name: "member-deleteaccount___en-au",
    path: "/en-au/member/deleteaccount",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/deleteaccount.vue")
  },
  {
    name: "member-deleteaccount___en-my",
    path: "/en-my/member/deleteaccount",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/deleteaccount.vue")
  },
  {
    name: "member-deleteaccount___en-ph",
    path: "/en-ph/member/deleteaccount",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/deleteaccount.vue")
  },
  {
    name: "member-deleteaccount___en-nz",
    path: "/en-nz/member/deleteaccount",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/deleteaccount.vue")
  },
  {
    name: "member-deleteaccount___en-ca",
    path: "/en-ca/member/deleteaccount",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/deleteaccount.vue")
  },
  {
    name: "member-deleteaccount___ja-jp",
    path: "/ja-jp/member/deleteaccount",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/deleteaccount.vue")
  },
  {
    name: "member-order___zh-cn",
    path: "/zh-cn/member/order",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/order.vue")
  },
  {
    name: "member-order___zh-hk",
    path: "/zh-hk/member/order",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/order.vue")
  },
  {
    name: "member-order___zh-tw",
    path: "/zh-tw/member/order",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/order.vue")
  },
  {
    name: "member-order___en-us",
    path: "/en-us/member/order",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/order.vue")
  },
  {
    name: "member-order___en-xx___default",
    path: "/member/order",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/order.vue")
  },
  {
    name: "member-order___en-xx",
    path: "/en-xx/member/order",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/order.vue")
  },
  {
    name: "member-order___en-gb",
    path: "/en-gb/member/order",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/order.vue")
  },
  {
    name: "member-order___en-hk",
    path: "/en-hk/member/order",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/order.vue")
  },
  {
    name: "member-order___de-de",
    path: "/de-de/member/order",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/order.vue")
  },
  {
    name: "member-order___en-sg",
    path: "/en-sg/member/order",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/order.vue")
  },
  {
    name: "member-order___en-au",
    path: "/en-au/member/order",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/order.vue")
  },
  {
    name: "member-order___en-my",
    path: "/en-my/member/order",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/order.vue")
  },
  {
    name: "member-order___en-ph",
    path: "/en-ph/member/order",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/order.vue")
  },
  {
    name: "member-order___en-nz",
    path: "/en-nz/member/order",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/order.vue")
  },
  {
    name: "member-order___en-ca",
    path: "/en-ca/member/order",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/order.vue")
  },
  {
    name: "member-order___ja-jp",
    path: "/ja-jp/member/order",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/order.vue")
  },
  {
    name: "member-passenger-components-trRow___zh-cn",
    path: "/zh-cn/member/passenger/components/trRow",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/components/trRow.vue")
  },
  {
    name: "member-passenger-components-trRow___zh-hk",
    path: "/zh-hk/member/passenger/components/trRow",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/components/trRow.vue")
  },
  {
    name: "member-passenger-components-trRow___zh-tw",
    path: "/zh-tw/member/passenger/components/trRow",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/components/trRow.vue")
  },
  {
    name: "member-passenger-components-trRow___en-us",
    path: "/en-us/member/passenger/components/trRow",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/components/trRow.vue")
  },
  {
    name: "member-passenger-components-trRow___en-xx___default",
    path: "/member/passenger/components/trRow",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/components/trRow.vue")
  },
  {
    name: "member-passenger-components-trRow___en-xx",
    path: "/en-xx/member/passenger/components/trRow",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/components/trRow.vue")
  },
  {
    name: "member-passenger-components-trRow___en-gb",
    path: "/en-gb/member/passenger/components/trRow",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/components/trRow.vue")
  },
  {
    name: "member-passenger-components-trRow___en-hk",
    path: "/en-hk/member/passenger/components/trRow",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/components/trRow.vue")
  },
  {
    name: "member-passenger-components-trRow___de-de",
    path: "/de-de/member/passenger/components/trRow",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/components/trRow.vue")
  },
  {
    name: "member-passenger-components-trRow___en-sg",
    path: "/en-sg/member/passenger/components/trRow",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/components/trRow.vue")
  },
  {
    name: "member-passenger-components-trRow___en-au",
    path: "/en-au/member/passenger/components/trRow",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/components/trRow.vue")
  },
  {
    name: "member-passenger-components-trRow___en-my",
    path: "/en-my/member/passenger/components/trRow",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/components/trRow.vue")
  },
  {
    name: "member-passenger-components-trRow___en-ph",
    path: "/en-ph/member/passenger/components/trRow",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/components/trRow.vue")
  },
  {
    name: "member-passenger-components-trRow___en-nz",
    path: "/en-nz/member/passenger/components/trRow",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/components/trRow.vue")
  },
  {
    name: "member-passenger-components-trRow___en-ca",
    path: "/en-ca/member/passenger/components/trRow",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/components/trRow.vue")
  },
  {
    name: "member-passenger-components-trRow___ja-jp",
    path: "/ja-jp/member/passenger/components/trRow",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/components/trRow.vue")
  },
  {
    name: "member-passenger-edit___zh-cn",
    path: "/zh-cn/member/passenger/edit",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/edit.vue")
  },
  {
    name: "member-passenger-edit___zh-hk",
    path: "/zh-hk/member/passenger/edit",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/edit.vue")
  },
  {
    name: "member-passenger-edit___zh-tw",
    path: "/zh-tw/member/passenger/edit",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/edit.vue")
  },
  {
    name: "member-passenger-edit___en-us",
    path: "/en-us/member/passenger/edit",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/edit.vue")
  },
  {
    name: "member-passenger-edit___en-xx___default",
    path: "/member/passenger/edit",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/edit.vue")
  },
  {
    name: "member-passenger-edit___en-xx",
    path: "/en-xx/member/passenger/edit",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/edit.vue")
  },
  {
    name: "member-passenger-edit___en-gb",
    path: "/en-gb/member/passenger/edit",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/edit.vue")
  },
  {
    name: "member-passenger-edit___en-hk",
    path: "/en-hk/member/passenger/edit",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/edit.vue")
  },
  {
    name: "member-passenger-edit___de-de",
    path: "/de-de/member/passenger/edit",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/edit.vue")
  },
  {
    name: "member-passenger-edit___en-sg",
    path: "/en-sg/member/passenger/edit",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/edit.vue")
  },
  {
    name: "member-passenger-edit___en-au",
    path: "/en-au/member/passenger/edit",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/edit.vue")
  },
  {
    name: "member-passenger-edit___en-my",
    path: "/en-my/member/passenger/edit",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/edit.vue")
  },
  {
    name: "member-passenger-edit___en-ph",
    path: "/en-ph/member/passenger/edit",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/edit.vue")
  },
  {
    name: "member-passenger-edit___en-nz",
    path: "/en-nz/member/passenger/edit",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/edit.vue")
  },
  {
    name: "member-passenger-edit___en-ca",
    path: "/en-ca/member/passenger/edit",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/edit.vue")
  },
  {
    name: "member-passenger-edit___ja-jp",
    path: "/ja-jp/member/passenger/edit",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/edit.vue")
  },
  {
    name: "member-passenger-list___zh-cn",
    path: "/zh-cn/member/passenger/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/list.vue")
  },
  {
    name: "member-passenger-list___zh-hk",
    path: "/zh-hk/member/passenger/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/list.vue")
  },
  {
    name: "member-passenger-list___zh-tw",
    path: "/zh-tw/member/passenger/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/list.vue")
  },
  {
    name: "member-passenger-list___en-us",
    path: "/en-us/member/passenger/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/list.vue")
  },
  {
    name: "member-passenger-list___en-xx___default",
    path: "/member/passenger/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/list.vue")
  },
  {
    name: "member-passenger-list___en-xx",
    path: "/en-xx/member/passenger/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/list.vue")
  },
  {
    name: "member-passenger-list___en-gb",
    path: "/en-gb/member/passenger/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/list.vue")
  },
  {
    name: "member-passenger-list___en-hk",
    path: "/en-hk/member/passenger/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/list.vue")
  },
  {
    name: "member-passenger-list___de-de",
    path: "/de-de/member/passenger/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/list.vue")
  },
  {
    name: "member-passenger-list___en-sg",
    path: "/en-sg/member/passenger/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/list.vue")
  },
  {
    name: "member-passenger-list___en-au",
    path: "/en-au/member/passenger/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/list.vue")
  },
  {
    name: "member-passenger-list___en-my",
    path: "/en-my/member/passenger/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/list.vue")
  },
  {
    name: "member-passenger-list___en-ph",
    path: "/en-ph/member/passenger/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/list.vue")
  },
  {
    name: "member-passenger-list___en-nz",
    path: "/en-nz/member/passenger/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/list.vue")
  },
  {
    name: "member-passenger-list___en-ca",
    path: "/en-ca/member/passenger/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/list.vue")
  },
  {
    name: "member-passenger-list___ja-jp",
    path: "/ja-jp/member/passenger/list",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/passenger/list.vue")
  },
  {
    name: "member-profile___zh-cn",
    path: "/zh-cn/member/profile",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/profile.vue")
  },
  {
    name: "member-profile___zh-hk",
    path: "/zh-hk/member/profile",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/profile.vue")
  },
  {
    name: "member-profile___zh-tw",
    path: "/zh-tw/member/profile",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/profile.vue")
  },
  {
    name: "member-profile___en-us",
    path: "/en-us/member/profile",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/profile.vue")
  },
  {
    name: "member-profile___en-xx___default",
    path: "/member/profile",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/profile.vue")
  },
  {
    name: "member-profile___en-xx",
    path: "/en-xx/member/profile",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/profile.vue")
  },
  {
    name: "member-profile___en-gb",
    path: "/en-gb/member/profile",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/profile.vue")
  },
  {
    name: "member-profile___en-hk",
    path: "/en-hk/member/profile",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/profile.vue")
  },
  {
    name: "member-profile___de-de",
    path: "/de-de/member/profile",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/profile.vue")
  },
  {
    name: "member-profile___en-sg",
    path: "/en-sg/member/profile",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/profile.vue")
  },
  {
    name: "member-profile___en-au",
    path: "/en-au/member/profile",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/profile.vue")
  },
  {
    name: "member-profile___en-my",
    path: "/en-my/member/profile",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/profile.vue")
  },
  {
    name: "member-profile___en-ph",
    path: "/en-ph/member/profile",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/profile.vue")
  },
  {
    name: "member-profile___en-nz",
    path: "/en-nz/member/profile",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/profile.vue")
  },
  {
    name: "member-profile___en-ca",
    path: "/en-ca/member/profile",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/profile.vue")
  },
  {
    name: "member-profile___ja-jp",
    path: "/ja-jp/member/profile",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/profile.vue")
  },
  {
    name: "member-traveler-components-TravelerCard___zh-cn",
    path: "/zh-cn/member/traveler/components/TravelerCard",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerCard.vue")
  },
  {
    name: "member-traveler-components-TravelerCard___zh-hk",
    path: "/zh-hk/member/traveler/components/TravelerCard",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerCard.vue")
  },
  {
    name: "member-traveler-components-TravelerCard___zh-tw",
    path: "/zh-tw/member/traveler/components/TravelerCard",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerCard.vue")
  },
  {
    name: "member-traveler-components-TravelerCard___en-us",
    path: "/en-us/member/traveler/components/TravelerCard",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerCard.vue")
  },
  {
    name: "member-traveler-components-TravelerCard___en-xx___default",
    path: "/member/traveler/components/TravelerCard",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerCard.vue")
  },
  {
    name: "member-traveler-components-TravelerCard___en-xx",
    path: "/en-xx/member/traveler/components/TravelerCard",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerCard.vue")
  },
  {
    name: "member-traveler-components-TravelerCard___en-gb",
    path: "/en-gb/member/traveler/components/TravelerCard",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerCard.vue")
  },
  {
    name: "member-traveler-components-TravelerCard___en-hk",
    path: "/en-hk/member/traveler/components/TravelerCard",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerCard.vue")
  },
  {
    name: "member-traveler-components-TravelerCard___de-de",
    path: "/de-de/member/traveler/components/TravelerCard",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerCard.vue")
  },
  {
    name: "member-traveler-components-TravelerCard___en-sg",
    path: "/en-sg/member/traveler/components/TravelerCard",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerCard.vue")
  },
  {
    name: "member-traveler-components-TravelerCard___en-au",
    path: "/en-au/member/traveler/components/TravelerCard",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerCard.vue")
  },
  {
    name: "member-traveler-components-TravelerCard___en-my",
    path: "/en-my/member/traveler/components/TravelerCard",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerCard.vue")
  },
  {
    name: "member-traveler-components-TravelerCard___en-ph",
    path: "/en-ph/member/traveler/components/TravelerCard",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerCard.vue")
  },
  {
    name: "member-traveler-components-TravelerCard___en-nz",
    path: "/en-nz/member/traveler/components/TravelerCard",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerCard.vue")
  },
  {
    name: "member-traveler-components-TravelerCard___en-ca",
    path: "/en-ca/member/traveler/components/TravelerCard",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerCard.vue")
  },
  {
    name: "member-traveler-components-TravelerCard___ja-jp",
    path: "/ja-jp/member/traveler/components/TravelerCard",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerCard.vue")
  },
  {
    name: "member-traveler-components-TravelerForm___zh-cn",
    path: "/zh-cn/member/traveler/components/TravelerForm",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerForm.vue")
  },
  {
    name: "member-traveler-components-TravelerForm___zh-hk",
    path: "/zh-hk/member/traveler/components/TravelerForm",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerForm.vue")
  },
  {
    name: "member-traveler-components-TravelerForm___zh-tw",
    path: "/zh-tw/member/traveler/components/TravelerForm",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerForm.vue")
  },
  {
    name: "member-traveler-components-TravelerForm___en-us",
    path: "/en-us/member/traveler/components/TravelerForm",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerForm.vue")
  },
  {
    name: "member-traveler-components-TravelerForm___en-xx___default",
    path: "/member/traveler/components/TravelerForm",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerForm.vue")
  },
  {
    name: "member-traveler-components-TravelerForm___en-xx",
    path: "/en-xx/member/traveler/components/TravelerForm",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerForm.vue")
  },
  {
    name: "member-traveler-components-TravelerForm___en-gb",
    path: "/en-gb/member/traveler/components/TravelerForm",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerForm.vue")
  },
  {
    name: "member-traveler-components-TravelerForm___en-hk",
    path: "/en-hk/member/traveler/components/TravelerForm",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerForm.vue")
  },
  {
    name: "member-traveler-components-TravelerForm___de-de",
    path: "/de-de/member/traveler/components/TravelerForm",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerForm.vue")
  },
  {
    name: "member-traveler-components-TravelerForm___en-sg",
    path: "/en-sg/member/traveler/components/TravelerForm",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerForm.vue")
  },
  {
    name: "member-traveler-components-TravelerForm___en-au",
    path: "/en-au/member/traveler/components/TravelerForm",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerForm.vue")
  },
  {
    name: "member-traveler-components-TravelerForm___en-my",
    path: "/en-my/member/traveler/components/TravelerForm",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerForm.vue")
  },
  {
    name: "member-traveler-components-TravelerForm___en-ph",
    path: "/en-ph/member/traveler/components/TravelerForm",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerForm.vue")
  },
  {
    name: "member-traveler-components-TravelerForm___en-nz",
    path: "/en-nz/member/traveler/components/TravelerForm",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerForm.vue")
  },
  {
    name: "member-traveler-components-TravelerForm___en-ca",
    path: "/en-ca/member/traveler/components/TravelerForm",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerForm.vue")
  },
  {
    name: "member-traveler-components-TravelerForm___ja-jp",
    path: "/ja-jp/member/traveler/components/TravelerForm",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/components/TravelerForm.vue")
  },
  {
    name: "member-traveler___zh-cn",
    path: "/zh-cn/member/traveler",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/index.vue")
  },
  {
    name: "member-traveler___zh-hk",
    path: "/zh-hk/member/traveler",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/index.vue")
  },
  {
    name: "member-traveler___zh-tw",
    path: "/zh-tw/member/traveler",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/index.vue")
  },
  {
    name: "member-traveler___en-us",
    path: "/en-us/member/traveler",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/index.vue")
  },
  {
    name: "member-traveler___en-xx___default",
    path: "/member/traveler",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/index.vue")
  },
  {
    name: "member-traveler___en-xx",
    path: "/en-xx/member/traveler",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/index.vue")
  },
  {
    name: "member-traveler___en-gb",
    path: "/en-gb/member/traveler",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/index.vue")
  },
  {
    name: "member-traveler___en-hk",
    path: "/en-hk/member/traveler",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/index.vue")
  },
  {
    name: "member-traveler___de-de",
    path: "/de-de/member/traveler",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/index.vue")
  },
  {
    name: "member-traveler___en-sg",
    path: "/en-sg/member/traveler",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/index.vue")
  },
  {
    name: "member-traveler___en-au",
    path: "/en-au/member/traveler",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/index.vue")
  },
  {
    name: "member-traveler___en-my",
    path: "/en-my/member/traveler",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/index.vue")
  },
  {
    name: "member-traveler___en-ph",
    path: "/en-ph/member/traveler",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/index.vue")
  },
  {
    name: "member-traveler___en-nz",
    path: "/en-nz/member/traveler",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/index.vue")
  },
  {
    name: "member-traveler___en-ca",
    path: "/en-ca/member/traveler",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/index.vue")
  },
  {
    name: "member-traveler___ja-jp",
    path: "/ja-jp/member/traveler",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/member/traveler/index.vue")
  },
  {
    name: "order-search___zh-cn",
    path: "/zh-cn/order/search",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/order/search.vue")
  },
  {
    name: "order-search___zh-hk",
    path: "/zh-hk/order/search",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/order/search.vue")
  },
  {
    name: "order-search___zh-tw",
    path: "/zh-tw/order/search",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/order/search.vue")
  },
  {
    name: "order-search___en-us",
    path: "/en-us/order/search",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/order/search.vue")
  },
  {
    name: "order-search___en-xx___default",
    path: "/order/search",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/order/search.vue")
  },
  {
    name: "order-search___en-xx",
    path: "/en-xx/order/search",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/order/search.vue")
  },
  {
    name: "order-search___en-gb",
    path: "/en-gb/order/search",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/order/search.vue")
  },
  {
    name: "order-search___en-hk",
    path: "/en-hk/order/search",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/order/search.vue")
  },
  {
    name: "order-search___de-de",
    path: "/de-de/order/search",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/order/search.vue")
  },
  {
    name: "order-search___en-sg",
    path: "/en-sg/order/search",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/order/search.vue")
  },
  {
    name: "order-search___en-au",
    path: "/en-au/order/search",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/order/search.vue")
  },
  {
    name: "order-search___en-my",
    path: "/en-my/order/search",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/order/search.vue")
  },
  {
    name: "order-search___en-ph",
    path: "/en-ph/order/search",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/order/search.vue")
  },
  {
    name: "order-search___en-nz",
    path: "/en-nz/order/search",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/order/search.vue")
  },
  {
    name: "order-search___en-ca",
    path: "/en-ca/order/search",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/order/search.vue")
  },
  {
    name: "order-search___ja-jp",
    path: "/ja-jp/order/search",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/order/search.vue")
  },
  {
    name: "passport-forgotpassword___zh-cn",
    path: "/zh-cn/passport/forgotpassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/forgotpassword.vue")
  },
  {
    name: "passport-forgotpassword___zh-hk",
    path: "/zh-hk/passport/forgotpassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/forgotpassword.vue")
  },
  {
    name: "passport-forgotpassword___zh-tw",
    path: "/zh-tw/passport/forgotpassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/forgotpassword.vue")
  },
  {
    name: "passport-forgotpassword___en-us",
    path: "/en-us/passport/forgotpassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/forgotpassword.vue")
  },
  {
    name: "passport-forgotpassword___en-xx___default",
    path: "/passport/forgotpassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/forgotpassword.vue")
  },
  {
    name: "passport-forgotpassword___en-xx",
    path: "/en-xx/passport/forgotpassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/forgotpassword.vue")
  },
  {
    name: "passport-forgotpassword___en-gb",
    path: "/en-gb/passport/forgotpassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/forgotpassword.vue")
  },
  {
    name: "passport-forgotpassword___en-hk",
    path: "/en-hk/passport/forgotpassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/forgotpassword.vue")
  },
  {
    name: "passport-forgotpassword___de-de",
    path: "/de-de/passport/forgotpassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/forgotpassword.vue")
  },
  {
    name: "passport-forgotpassword___en-sg",
    path: "/en-sg/passport/forgotpassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/forgotpassword.vue")
  },
  {
    name: "passport-forgotpassword___en-au",
    path: "/en-au/passport/forgotpassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/forgotpassword.vue")
  },
  {
    name: "passport-forgotpassword___en-my",
    path: "/en-my/passport/forgotpassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/forgotpassword.vue")
  },
  {
    name: "passport-forgotpassword___en-ph",
    path: "/en-ph/passport/forgotpassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/forgotpassword.vue")
  },
  {
    name: "passport-forgotpassword___en-nz",
    path: "/en-nz/passport/forgotpassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/forgotpassword.vue")
  },
  {
    name: "passport-forgotpassword___en-ca",
    path: "/en-ca/passport/forgotpassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/forgotpassword.vue")
  },
  {
    name: "passport-forgotpassword___ja-jp",
    path: "/ja-jp/passport/forgotpassword",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/forgotpassword.vue")
  },
  {
    name: "passport-signin___zh-cn",
    path: "/zh-cn/passport/signin",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signin.vue")
  },
  {
    name: "passport-signin___zh-hk",
    path: "/zh-hk/passport/signin",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signin.vue")
  },
  {
    name: "passport-signin___zh-tw",
    path: "/zh-tw/passport/signin",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signin.vue")
  },
  {
    name: "passport-signin___en-us",
    path: "/en-us/passport/signin",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signin.vue")
  },
  {
    name: "passport-signin___en-xx___default",
    path: "/passport/signin",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signin.vue")
  },
  {
    name: "passport-signin___en-xx",
    path: "/en-xx/passport/signin",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signin.vue")
  },
  {
    name: "passport-signin___en-gb",
    path: "/en-gb/passport/signin",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signin.vue")
  },
  {
    name: "passport-signin___en-hk",
    path: "/en-hk/passport/signin",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signin.vue")
  },
  {
    name: "passport-signin___de-de",
    path: "/de-de/passport/signin",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signin.vue")
  },
  {
    name: "passport-signin___en-sg",
    path: "/en-sg/passport/signin",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signin.vue")
  },
  {
    name: "passport-signin___en-au",
    path: "/en-au/passport/signin",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signin.vue")
  },
  {
    name: "passport-signin___en-my",
    path: "/en-my/passport/signin",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signin.vue")
  },
  {
    name: "passport-signin___en-ph",
    path: "/en-ph/passport/signin",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signin.vue")
  },
  {
    name: "passport-signin___en-nz",
    path: "/en-nz/passport/signin",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signin.vue")
  },
  {
    name: "passport-signin___en-ca",
    path: "/en-ca/passport/signin",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signin.vue")
  },
  {
    name: "passport-signin___ja-jp",
    path: "/ja-jp/passport/signin",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signin.vue")
  },
  {
    name: "passport-signup___zh-cn",
    path: "/zh-cn/passport/signup",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signup.vue")
  },
  {
    name: "passport-signup___zh-hk",
    path: "/zh-hk/passport/signup",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signup.vue")
  },
  {
    name: "passport-signup___zh-tw",
    path: "/zh-tw/passport/signup",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signup.vue")
  },
  {
    name: "passport-signup___en-us",
    path: "/en-us/passport/signup",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signup.vue")
  },
  {
    name: "passport-signup___en-xx___default",
    path: "/passport/signup",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signup.vue")
  },
  {
    name: "passport-signup___en-xx",
    path: "/en-xx/passport/signup",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signup.vue")
  },
  {
    name: "passport-signup___en-gb",
    path: "/en-gb/passport/signup",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signup.vue")
  },
  {
    name: "passport-signup___en-hk",
    path: "/en-hk/passport/signup",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signup.vue")
  },
  {
    name: "passport-signup___de-de",
    path: "/de-de/passport/signup",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signup.vue")
  },
  {
    name: "passport-signup___en-sg",
    path: "/en-sg/passport/signup",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signup.vue")
  },
  {
    name: "passport-signup___en-au",
    path: "/en-au/passport/signup",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signup.vue")
  },
  {
    name: "passport-signup___en-my",
    path: "/en-my/passport/signup",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signup.vue")
  },
  {
    name: "passport-signup___en-ph",
    path: "/en-ph/passport/signup",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signup.vue")
  },
  {
    name: "passport-signup___en-nz",
    path: "/en-nz/passport/signup",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signup.vue")
  },
  {
    name: "passport-signup___en-ca",
    path: "/en-ca/passport/signup",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signup.vue")
  },
  {
    name: "passport-signup___ja-jp",
    path: "/ja-jp/passport/signup",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/signup.vue")
  },
  {
    name: "passport-tracker___zh-cn",
    path: "/zh-cn/passport/tracker",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/tracker.ts")
  },
  {
    name: "passport-tracker___zh-hk",
    path: "/zh-hk/passport/tracker",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/tracker.ts")
  },
  {
    name: "passport-tracker___zh-tw",
    path: "/zh-tw/passport/tracker",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/tracker.ts")
  },
  {
    name: "passport-tracker___en-us",
    path: "/en-us/passport/tracker",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/tracker.ts")
  },
  {
    name: "passport-tracker___en-xx___default",
    path: "/passport/tracker",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/tracker.ts")
  },
  {
    name: "passport-tracker___en-xx",
    path: "/en-xx/passport/tracker",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/tracker.ts")
  },
  {
    name: "passport-tracker___en-gb",
    path: "/en-gb/passport/tracker",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/tracker.ts")
  },
  {
    name: "passport-tracker___en-hk",
    path: "/en-hk/passport/tracker",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/tracker.ts")
  },
  {
    name: "passport-tracker___de-de",
    path: "/de-de/passport/tracker",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/tracker.ts")
  },
  {
    name: "passport-tracker___en-sg",
    path: "/en-sg/passport/tracker",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/tracker.ts")
  },
  {
    name: "passport-tracker___en-au",
    path: "/en-au/passport/tracker",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/tracker.ts")
  },
  {
    name: "passport-tracker___en-my",
    path: "/en-my/passport/tracker",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/tracker.ts")
  },
  {
    name: "passport-tracker___en-ph",
    path: "/en-ph/passport/tracker",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/tracker.ts")
  },
  {
    name: "passport-tracker___en-nz",
    path: "/en-nz/passport/tracker",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/tracker.ts")
  },
  {
    name: "passport-tracker___en-ca",
    path: "/en-ca/passport/tracker",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/tracker.ts")
  },
  {
    name: "passport-tracker___ja-jp",
    path: "/ja-jp/passport/tracker",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/passport/tracker.ts")
  },
  {
    name: "pc-keepalive___zh-cn",
    path: "/zh-cn/pc/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pc/keepalive/index.vue")
  },
  {
    name: "pc-keepalive___zh-hk",
    path: "/zh-hk/pc/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pc/keepalive/index.vue")
  },
  {
    name: "pc-keepalive___zh-tw",
    path: "/zh-tw/pc/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pc/keepalive/index.vue")
  },
  {
    name: "pc-keepalive___en-us",
    path: "/en-us/pc/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pc/keepalive/index.vue")
  },
  {
    name: "pc-keepalive___en-xx___default",
    path: "/pc/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pc/keepalive/index.vue")
  },
  {
    name: "pc-keepalive___en-xx",
    path: "/en-xx/pc/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pc/keepalive/index.vue")
  },
  {
    name: "pc-keepalive___en-gb",
    path: "/en-gb/pc/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pc/keepalive/index.vue")
  },
  {
    name: "pc-keepalive___en-hk",
    path: "/en-hk/pc/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pc/keepalive/index.vue")
  },
  {
    name: "pc-keepalive___de-de",
    path: "/de-de/pc/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pc/keepalive/index.vue")
  },
  {
    name: "pc-keepalive___en-sg",
    path: "/en-sg/pc/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pc/keepalive/index.vue")
  },
  {
    name: "pc-keepalive___en-au",
    path: "/en-au/pc/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pc/keepalive/index.vue")
  },
  {
    name: "pc-keepalive___en-my",
    path: "/en-my/pc/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pc/keepalive/index.vue")
  },
  {
    name: "pc-keepalive___en-ph",
    path: "/en-ph/pc/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pc/keepalive/index.vue")
  },
  {
    name: "pc-keepalive___en-nz",
    path: "/en-nz/pc/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pc/keepalive/index.vue")
  },
  {
    name: "pc-keepalive___en-ca",
    path: "/en-ca/pc/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pc/keepalive/index.vue")
  },
  {
    name: "pc-keepalive___ja-jp",
    path: "/ja-jp/pc/keepalive",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pc/keepalive/index.vue")
  },
  {
    name: "pinia___zh-cn",
    path: "/zh-cn/pinia",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pinia.vue")
  },
  {
    name: "pinia___zh-hk",
    path: "/zh-hk/pinia",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pinia.vue")
  },
  {
    name: "pinia___zh-tw",
    path: "/zh-tw/pinia",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pinia.vue")
  },
  {
    name: "pinia___en-us",
    path: "/en-us/pinia",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pinia.vue")
  },
  {
    name: "pinia___en-xx___default",
    path: "/pinia",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pinia.vue")
  },
  {
    name: "pinia___en-xx",
    path: "/en-xx/pinia",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pinia.vue")
  },
  {
    name: "pinia___en-gb",
    path: "/en-gb/pinia",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pinia.vue")
  },
  {
    name: "pinia___en-hk",
    path: "/en-hk/pinia",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pinia.vue")
  },
  {
    name: "pinia___de-de",
    path: "/de-de/pinia",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pinia.vue")
  },
  {
    name: "pinia___en-sg",
    path: "/en-sg/pinia",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pinia.vue")
  },
  {
    name: "pinia___en-au",
    path: "/en-au/pinia",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pinia.vue")
  },
  {
    name: "pinia___en-my",
    path: "/en-my/pinia",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pinia.vue")
  },
  {
    name: "pinia___en-ph",
    path: "/en-ph/pinia",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pinia.vue")
  },
  {
    name: "pinia___en-nz",
    path: "/en-nz/pinia",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pinia.vue")
  },
  {
    name: "pinia___en-ca",
    path: "/en-ca/pinia",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pinia.vue")
  },
  {
    name: "pinia___ja-jp",
    path: "/ja-jp/pinia",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/pinia.vue")
  },
  {
    name: "testing___zh-cn",
    path: "/zh-cn/testing",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/testing/index.vue")
  },
  {
    name: "testing___zh-hk",
    path: "/zh-hk/testing",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/testing/index.vue")
  },
  {
    name: "testing___zh-tw",
    path: "/zh-tw/testing",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/testing/index.vue")
  },
  {
    name: "testing___en-us",
    path: "/en-us/testing",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/testing/index.vue")
  },
  {
    name: "testing___en-xx___default",
    path: "/testing",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/testing/index.vue")
  },
  {
    name: "testing___en-xx",
    path: "/en-xx/testing",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/testing/index.vue")
  },
  {
    name: "testing___en-gb",
    path: "/en-gb/testing",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/testing/index.vue")
  },
  {
    name: "testing___en-hk",
    path: "/en-hk/testing",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/testing/index.vue")
  },
  {
    name: "testing___de-de",
    path: "/de-de/testing",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/testing/index.vue")
  },
  {
    name: "testing___en-sg",
    path: "/en-sg/testing",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/testing/index.vue")
  },
  {
    name: "testing___en-au",
    path: "/en-au/testing",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/testing/index.vue")
  },
  {
    name: "testing___en-my",
    path: "/en-my/testing",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/testing/index.vue")
  },
  {
    name: "testing___en-ph",
    path: "/en-ph/testing",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/testing/index.vue")
  },
  {
    name: "testing___en-nz",
    path: "/en-nz/testing",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/testing/index.vue")
  },
  {
    name: "testing___en-ca",
    path: "/en-ca/testing",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/testing/index.vue")
  },
  {
    name: "testing___ja-jp",
    path: "/ja-jp/testing",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/testing/index.vue")
  },
  {
    name: "travelguide-nameId___zh-cn",
    path: "/zh-cn/travelguide/:nameId()",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/travelguide/[nameId].vue")
  },
  {
    name: "travelguide-nameId___zh-hk",
    path: "/zh-hk/travelguide/:nameId()",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/travelguide/[nameId].vue")
  },
  {
    name: "travelguide-nameId___zh-tw",
    path: "/zh-tw/travelguide/:nameId()",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/travelguide/[nameId].vue")
  },
  {
    name: "travelguide-nameId___en-us",
    path: "/en-us/travelguide/:nameId()",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/travelguide/[nameId].vue")
  },
  {
    name: "travelguide-nameId___en-xx___default",
    path: "/travelguide/:nameId()",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/travelguide/[nameId].vue")
  },
  {
    name: "travelguide-nameId___en-xx",
    path: "/en-xx/travelguide/:nameId()",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/travelguide/[nameId].vue")
  },
  {
    name: "travelguide-nameId___en-gb",
    path: "/en-gb/travelguide/:nameId()",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/travelguide/[nameId].vue")
  },
  {
    name: "travelguide-nameId___en-hk",
    path: "/en-hk/travelguide/:nameId()",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/travelguide/[nameId].vue")
  },
  {
    name: "travelguide-nameId___de-de",
    path: "/de-de/travelguide/:nameId()",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/travelguide/[nameId].vue")
  },
  {
    name: "travelguide-nameId___en-sg",
    path: "/en-sg/travelguide/:nameId()",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/travelguide/[nameId].vue")
  },
  {
    name: "travelguide-nameId___en-au",
    path: "/en-au/travelguide/:nameId()",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/travelguide/[nameId].vue")
  },
  {
    name: "travelguide-nameId___en-my",
    path: "/en-my/travelguide/:nameId()",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/travelguide/[nameId].vue")
  },
  {
    name: "travelguide-nameId___en-ph",
    path: "/en-ph/travelguide/:nameId()",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/travelguide/[nameId].vue")
  },
  {
    name: "travelguide-nameId___en-nz",
    path: "/en-nz/travelguide/:nameId()",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/travelguide/[nameId].vue")
  },
  {
    name: "travelguide-nameId___en-ca",
    path: "/en-ca/travelguide/:nameId()",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/travelguide/[nameId].vue")
  },
  {
    name: "travelguide-nameId___ja-jp",
    path: "/ja-jp/travelguide/:nameId()",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/travelguide/[nameId].vue")
  },
  {
    name: "unsubscribe___zh-cn",
    path: "/zh-cn/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/unsubscribe/index.vue")
  },
  {
    name: "unsubscribe___zh-hk",
    path: "/zh-hk/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/unsubscribe/index.vue")
  },
  {
    name: "unsubscribe___zh-tw",
    path: "/zh-tw/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/unsubscribe/index.vue")
  },
  {
    name: "unsubscribe___en-us",
    path: "/en-us/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/unsubscribe/index.vue")
  },
  {
    name: "unsubscribe___en-xx___default",
    path: "/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/unsubscribe/index.vue")
  },
  {
    name: "unsubscribe___en-xx",
    path: "/en-xx/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/unsubscribe/index.vue")
  },
  {
    name: "unsubscribe___en-gb",
    path: "/en-gb/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/unsubscribe/index.vue")
  },
  {
    name: "unsubscribe___en-hk",
    path: "/en-hk/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/unsubscribe/index.vue")
  },
  {
    name: "unsubscribe___de-de",
    path: "/de-de/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/unsubscribe/index.vue")
  },
  {
    name: "unsubscribe___en-sg",
    path: "/en-sg/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/unsubscribe/index.vue")
  },
  {
    name: "unsubscribe___en-au",
    path: "/en-au/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/unsubscribe/index.vue")
  },
  {
    name: "unsubscribe___en-my",
    path: "/en-my/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/unsubscribe/index.vue")
  },
  {
    name: "unsubscribe___en-ph",
    path: "/en-ph/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/unsubscribe/index.vue")
  },
  {
    name: "unsubscribe___en-nz",
    path: "/en-nz/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/unsubscribe/index.vue")
  },
  {
    name: "unsubscribe___en-ca",
    path: "/en-ca/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/unsubscribe/index.vue")
  },
  {
    name: "unsubscribe___ja-jp",
    path: "/ja-jp/unsubscribe",
    component: () => import("/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/pages/unsubscribe/index.vue")
  }
]