import { httpGet } from './http'

const prefix = '/pcapi'

export const apiURL = {
  // 关于我们 - 分类列表
  aboutCategoryList(params = {}) {
    return httpGet(`${prefix}/about/category/list`, params)
  },
  // 关于我们 - 内容列表
  aboutContentList(params = {}) {
    return httpGet(`${prefix}/about/content/list`, params)
  },
}
