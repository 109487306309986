const globalData = {
  vueApp: {},
  readyArr: [],
  isReady: false,
  setApp(app) {
    this.vueApp = app
    this.isReady = true
    this.eachReady()
  },
  getApp() {
    return this.vueApp
  },
  getCurrentInstance() {
    try {
      return this.vueApp.config.globalProperties
    }
    catch (e) {
      console.error('未获取到对象')
      return {}
    }
  },
  ready(fn) {
    if (this.isReady) {
      fn && fn()
    }
    else {
      this.readyArr.push(fn)
    }
  },
  eachReady(fn = null) {
    fn && fn()
    const poolData = this.readyArr.shift()
    if (poolData) {
      this.eachReady(poolData)
    }
  },
}

export default globalData
