import type * as SentryType from '@sentry/vue/types'
import { reserveDecimal } from '@/utils/util'

// import * as Sentry from '@sentry/vue';
// 避免ssg重复初始化
let inited = false

const penddingEvents: Array<Record<string, any>> = []
let Sentry: typeof SentryType | null = null

export async function initSentry() {
  const runtimeConfig = useRuntimeConfig()
  if (runtimeConfig.public.envData.VITE_PACK_ENV !== 'dev' && typeof window !== 'undefined') {
    if (inited) { return }
    inited = true

    Sentry = (await import('@sentry/vue')) as typeof SentryType
    // 设置用户信息
    Sentry?.setUser({
      id: useComCookie('userid').value || '',
    })
    Sentry?.init({
      dsn: 'https://d3e4f93d9e2e77f157d069775a4f86e1@st.hopegoo.com/6',
      // 环境
      environment: runtimeConfig.public.envData.VITE_PACK_ENV,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      beforeSendTransaction(transaction) {
        // const startTime = performance.now();
        // 我会在这里发送 transaction 前，做一些自定义的处理。
        // 针对资源加载的span，添加资源加载的一些细节时间，用于判断哪个环节耗时较长
        if (
          ['navigation', 'pageload'].includes(
            transaction.contexts?.trace?.op || '',
          )
        ) {
          const resourceSpans = transaction.spans?.filter((span) => {
            return /^resource\.\w+$/.test(span.op || '')
          })
          // 当前资源加载的timing
          const resourcesMap = resourcesTiming()
          if (resourcesMap) {
            // 将资源加载时间添加到span的attributes中
            resourceSpans?.forEach((span) => {
              const extendAttributes = resourcesMap[span.name]
              if (extendAttributes) {
                // @ts-expect-error
                span.setAttributes(extendAttributes)
              }
            })
          }
        }
        // const endTime = performance.now();
        // const executionTime = endTime - startTime;
        // console.log('Execution time: ' + executionTime + 'ms');
        return transaction
      },
      beforeBreadcrumb(breadcrumb) {
        switch (breadcrumb.category) {
          case 'console':
            // 忽略埋点和voncosle的日志
            if (breadcrumb.message?.match(/^\[system\]|埋点/)) {
              return null
            }
            break
          default:
            break
        }
        return breadcrumb
      },
      beforeSend(event, hint) {
        event.extra = event.extra || {}
        // 接口请求异常的处理
        if (hint.originalException != null) {
          try {
            const originalException = hint.originalException as {
              /**
               * 如果是请求异常，会在exception中携带__url表示具体的请求
               *
               * 这有助于业务在sentry中查看请求异常时，能够快速定位到具体的请求
               *  */
              __url?: string
            }
            const { __url } = originalException || {}
            event.exception?.values?.forEach((value) => {
              if (
                value.type === 'UnhandledRejection'
                // 如果有url, 则认为是请求导致的错误
                && __url
              ) {
                value.value = `Request UnhandledRejection: ${__url}`
              }
            })
          }
          catch (error) {
            // ignore
          }
        }

        return event
      },

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,

      // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https?:\/\/www\.(\w+\.)?hopegoo\.com/],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.05,
      replaysOnErrorSampleRate: 1.0,
    })

    // 处理之前的事件
    penddingEvents.forEach(({ fn, callback, args }) =>
      invokeSentry(fn, callback, ...args),
    )
    penddingEvents.length = 0
  }
}

/**
 * 调用sentry方法
 */
function invokeSentry<T = null>(
  fn: keyof typeof SentryType,
  callback: ((sentryResult: T) => void) | undefined,
  ...args: any
): T {
  if (!Sentry) {
    penddingEvents.push({ fn, args, callback })
    return null as T
  }
  else {
    // @ts-expect-error
    const result = Sentry[fn]?.(...args)
    typeof callback === 'function' && callback(result)
    return result
  }
}

/**
 * 上报错误异常
 * @param err
 */
export const captureException: typeof SentryType.captureException = function (
  ...args
) {
  return invokeSentry('captureException', undefined, args)
}
/**
 * 上报错误信息, 当sentry没有加载完成时，会将错误信息缓存起来，等待sentry加载完成后再上报
 *
 * @param message - 错误信息
 * @returns sentry 没加载完时 返回null，否则返回id
 */
export const captureMessage: typeof SentryType.captureMessage = function (
  ...args
) {
  return invokeSentry<string>('captureMessage', undefined, ...args)
}

/**
 * 添加非活动跨度，可以创建独立跨度。
 * 当您的工作组合在一个父范围下，但独立于当前活动范围时，这很有用。
 *
 * https://docs.sentry.io/platforms/javascript/guides/vue/performance/instrumentation/custom-instrumentation/#start-inactive-spans
 *
 * @param callback - 回调函数, 会在span创建成功后调用，兼容sentry异步加载的情况。
 * @param args - 参数同 {@link SentryType.startInactiveSpan}
 * @returns
 */
export const startInactiveSpan: (
  callback: ((sentryResult: SentryType.Span) => void) | undefined,
  ...args: Parameters<typeof SentryType.startInactiveSpan>
) => void = function (callback, ...args) {
  return invokeSentry('startInactiveSpan', callback, ...args)
}

/** 自定义资源的耗时统计 */
interface CustomResourcesTiming {
  /** 用了多久开始处理跟踪和重定向资源 */
  fet_s: string
  /** 用了多久开始连接到服务器 */
  con_s: string
  /** 用了多久完成服务器的连接 */
  con_e: string
  /** 用了多久开始请求资源 */
  req_s: string
  /** 用了多久资源请求首包返回 */
  res_s: string
  /** 用了多久资源请求完成 */
  res_e: string
}
/**
 * 统计资源加载时间
 */
export function resourcesTiming() {
  if (typeof performance !== 'object') { return null }
  try {
    // https://developer.mozilla.org/en-US/docs/Web/API/PerformanceResourceTiming
    const resources = performance.getEntriesByType('resource')
    const unit = 'ms'
    const SAVE_DECIMAL = 1
    const resourcesMap = (resources as PerformanceResourceTiming[]).reduce(
      (acc, entry) => {
        acc[entry.name.replace(window.location.origin, '')] = {
          // 用了多久开始处理跟踪和重定向资源
          fet_s:
            reserveDecimal(entry.fetchStart - entry.startTime, SAVE_DECIMAL)
            + unit,
          // 用了多久开始连接到服务器
          con_s:
            reserveDecimal(
              entry.connectStart - entry.fetchStart,
              SAVE_DECIMAL,
            ) + unit,
          // 用了多久完成连接到服务器
          con_e:
            reserveDecimal(
              entry.connectEnd - entry.connectStart,
              SAVE_DECIMAL,
            ) + unit,
          // 用了多久开始请求资源
          req_s:
            reserveDecimal(
              entry.requestStart - entry.connectEnd,
              SAVE_DECIMAL,
            ) + unit,
          // 用了多久资源请求首包返回
          res_s:
            reserveDecimal(
              entry.responseStart - entry.requestStart,
              SAVE_DECIMAL,
            ) + unit,
          // 用了多久资源请求完成
          res_e:
            reserveDecimal(
              entry.responseEnd - entry.responseStart,
              SAVE_DECIMAL,
            ) + unit,
        }
        return acc
      },
      {} as Record<string, CustomResourcesTiming>,
    )

    return resourcesMap
  }
  catch (error) {
    return null
  }
}
