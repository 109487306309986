import { v4 as uuidv4 } from 'uuid'
import { initSentry } from '../sentry'
import { defaultLangs } from './defaultLangInfo'
import { configDayjsLocal } from './dayjs'

export function InitApp() {
  const { locale, setLocale, getLocaleCookie, setLocaleCookie } = useI18n()
  const cookieLocale1 = getLocaleCookie()
  const cookieLocale2 = useComCookie('locale')
  // console.log('InitApp: cookieLocale1 = ', cookieLocale1)
  // console.log('InitApp: cookieLocale2 = ', cookieLocale2)

  const route = useRoute()
  const fullPath = route.fullPath
  initSentry()
  let routeMatchLocale = null
  // 正则表达式模式
  const pattern = /\/([a-z]{2}-[a-z]{2})/i
  // 使用正则表达式进行匹配
  const matches = fullPath.match(pattern)
  // 输出匹配结果
  if (matches && matches.length > 1) { routeMatchLocale = matches[1].toLocaleLowerCase() }

  if (routeMatchLocale && routeMatchLocale.length > 0) {
    if (routeMatchLocale === 'zh-cn') {
      // 替换zh-cn为zh-hk
      navigateTo(`/zh-hk${fullPath.replace('/zh-cn', '')}`)
      return
    }
  }

  let tempLocale = locale.value
  if (routeMatchLocale && routeMatchLocale.length > 0) {
    tempLocale = routeMatchLocale
  }
  else if (cookieLocale1 || cookieLocale2.value) {
    const cookieLocale = cookieLocale1 || cookieLocale2.value
    if (cookieLocale && cookieLocale.length > 0) { tempLocale = cookieLocale.toLocaleLowerCase() }
  }

  // 如果是中文，替换为en-us
  if (tempLocale && tempLocale === 'zh-cn') { tempLocale = 'en-us' }

  const defaultConfig = {
    configDesc: '<p>英语-美国</p>',
    configUuid: 'lcc6e21fc539d4e4f1f890b6e06044cd9d2',
    countryCode: '44',
    currency: 'USD',
    domain: '',
    icon: 'flag-us',
    imageUrl: '',
    isValid: 1,
    language: 'EN',
    languageName: 'English (United States)',
    locale: 'en-us',
    regionCode: 'US',
  }
  let tempConfig = defaultConfig
  defaultLangs.forEach((item) => {
    if (item.locale === tempLocale) { tempConfig = item }
  })

  locale.value = tempConfig.locale
  setLocaleCookie(tempConfig.locale)
  setLocale(tempConfig.locale)
  cookieLocale2.value = tempConfig.locale

  // 配置dayjs的locale
  configDayjsLocal(tempConfig.locale)

  const platformNoCookie = useComCookie('platformNo')
  platformNoCookie.value = '1'

  const currencyCookies = useComCookie('currency')
  if (!currencyCookies.value || !$utils.isValidCurrency(currencyCookies.value)) {
    currencyCookies.value = tempConfig.currency
  }

  const IdentifierCookies = useComCookie('Identifier', { disEncode: true })
  if (!IdentifierCookies.value || !IdentifierCookies.value.startsWith('ssid=')) {
    let tempUUID = uuidv4()
    tempUUID = tempUUID.replace(/-/g, '')
    IdentifierCookies.value = `ssid=${tempUUID}`
  }

  // CNSEInfo
  const CNSEInfoCookies = useComCookie('CNSEInfo', { disEncode: true, time: 60 * 60 * 12 })
  const CNSEInfo = {
    RefId: '',
    tcbdkeyid: '',
    SEFrom: '',
    SEKeyWords: '',
    RefUrl: '',
  }
  // 从CNSEInfo中获取 RefId、tcbdkeyid、SEFrom、SEKeyWords、RefUrl的value，以key=value格式，用&拼接
  if (CNSEInfoCookies.value && CNSEInfoCookies.value.length > 0) {
    const CNSEInfoArray = CNSEInfoCookies.value.split('&')
    CNSEInfoArray.forEach((item) => {
      const tempArray = item.split('=')
      if (tempArray.length === 2) {
        const key = tempArray[0]
        const value = tempArray[1]
        if (key === 'RefId') { CNSEInfo.RefId = value }
        else if (key === 'tcbdkeyid') { CNSEInfo.tcbdkeyid = value }
        else if (key === 'SEFrom') { CNSEInfo.SEFrom = value }
        else if (key === 'SEKeyWords') { CNSEInfo.SEKeyWords = value }
        else if (key === 'RefUrl') { CNSEInfo.RefUrl = value }
      }
    })
  }
  // 获取url中的参数refid
  const urlRefId = $utils.getUrlParam('refid') || ''
  if (urlRefId && urlRefId.length > 0) {
    CNSEInfoCookies.value = null
    CNSEInfo.RefId = urlRefId
  }
  // 生成CNSEInfo的value
  CNSEInfoCookies.value = `RefId=${CNSEInfo.RefId}&tcbdkeyid=${CNSEInfo.tcbdkeyid}&SEFrom=${CNSEInfo.SEFrom}&SEKeyWords=${CNSEInfo.SEKeyWords}&RefUrl=${CNSEInfo.RefUrl}`

  console.log('InitApp: locale.value = ', locale.value)

  // Redirect to the correct locale
  if (process.client) {
    if (routeMatchLocale) {
      const switchLocalePath = useSwitchLocalePath()
      const url = switchLocalePath(locale.value)

      // 检测新url中是否存在locale，并与当前locale不同，如果不同则跳转
      const localePattern = /\/([a-z]{2}-[a-z]{2})/i
      const localeMatches = url.match(localePattern)
      if (localeMatches && localeMatches.length > 1) {
        const newLocale = localeMatches[1].toLocaleLowerCase()
        if (newLocale !== locale.value) {
          location.href = url
        }
      }
    }

    // 初始化Modify质检工具
    const runtimeConfig = useRuntimeConfig()
    const env = runtimeConfig.public.envData.VITE_PACK_ENV
    // @ts-expect-error
    if (env !== 'prod' && typeof window.Tci18nQualityModify !== 'undefined') {
      // @ts-expect-error
      const tci18nQualityModify = new window.Tci18nQualityModify({
        languageKey: tempLocale,
        watchDOMChange: true,
        watchDOM: 'document.body',
        workId: '',
        userName: '',
        projectId: 'project_204b06b4fa2d4fe4abd96787d96e6843',
        origin: window.location.href, // 完整链接方便质检员定位页面
      })
      console.log(tci18nQualityModify, '贴心小译Modify工具初始化成功')
    }
  }
}
