import { defineStore } from 'pinia'
import { httpGet } from '@/utils/http'

enum CommonRouteCategoryMap {
  'about-us' = '1',
  'terms-and-conditions' = '2',
  'privacy-policy' = '3',
  'why-book-with-us' = '4',
}

export const useCommonConfig = defineStore('common', {
  state: () => {
    return {
      config: {},
      businessBanners: {},
      commonRouteMap: {
        [`categoryId_${CommonRouteCategoryMap['about-us']}`]: '/common/about-us',
        [`categoryId_${CommonRouteCategoryMap['terms-and-conditions']}`]: '/common/terms-and-conditions',
        [`categoryId_${CommonRouteCategoryMap['privacy-policy']}`]: '/common/privacy-policy',
        [`categoryId_${CommonRouteCategoryMap['why-book-with-us']}`]: '/common/about-us/why-book-with-us',
        // categoryId_5: '/common/about-us/how-we-started',
      },
      commonRouteCategoryMap: {
        'about-us': CommonRouteCategoryMap['about-us'],
        'terms-and-conditions': CommonRouteCategoryMap['terms-and-conditions'],
        'privacy-policy': CommonRouteCategoryMap['privacy-policy'],
        'why-book-with-us': CommonRouteCategoryMap['why-book-with-us'],
      } as any,
      commonMenuList: [] as any[],
      tid: null as any,
      times: 0,
    }
  },
  getters: {
  },
  actions: {
    async getCommonConfig() {
      try {
        const res = await httpGet('/pcapi/config/info', { configKey: 'intl_pc_global_config' })
        if (res?.code === 0) {
          this.config = JSON.parse(res.data)
        }
      }
      catch (error) {}
    },
    // async getBusinessBanners() {
    //   try {
    //     const res = await httpGet('/pcapi/config/info', { configKey: 'intl_pc_business_banners' })
    //     if (res?.code === 0) {
    //       this.businessBanners = JSON.parse(res.data)
    //     }
    //   }
    //   catch (error) {}
    // },
    setCommonMenuList(list: any[]) {
      this.commonMenuList = list || []
    },
    async queryCommonData(route: any, cb: any) {
      if (this.commonMenuList.length === 0 && this.times < 10) {
        clearTimeout(this.tid)
        this.times += 1
        this.tid = setTimeout(() => {
          clearTimeout(this.tid)
          this.queryCommonData(route, cb)
        }, 300)

        return
      }

      const pathArr = route.path.split('/')
      const lastPath = pathArr[pathArr.length - 1]
      const categoryId = this.commonRouteCategoryMap[lastPath]

      if (!categoryId) { return null }

      const res = await apiURL.aboutContentList({ categoryId })

      if (res?.code === 0) {
        const menuItem = this.commonMenuList.filter((item: any) => `${item.categoryId}` === categoryId)[0]
        cb && cb(res.data[0]?.content || '', menuItem)
      }
    },
  },
})
