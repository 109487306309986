<template>
  <div class="custom-footer">
    <div id="init_footer" />
  </div>
</template>

<style lang="scss" scoped>
.custom-footer {
  position: relative;
  background-color: white;
}
</style>
