import MobileDetect from 'mobile-detect'
import { defineNuxtPlugin, useRequestHeaders } from '#app'

export default defineNuxtPlugin(() => {
  const requestUrl = useRequestURL()
  const headers = useRequestHeaders()
  const request = useRequestURL()
  const md = process.server
    ? new MobileDetect(headers['user-agent'])
    : new MobileDetect(navigator.userAgent)
  const isMobile = md.phone() !== null || md.mobile() === 'UnknownMobile'
  const isTablet = md.tablet() !== null || md.mobile() === 'UnknownTablet'
  const isDesktop = !isMobile && !isTablet

  if (!isDesktop) {
    if (request.pathname.includes('/common/email-us')) {
      const arr = request.pathname.split('/common/email-us')
      // console.log('arr', arr)

      navigateTo(`/m${arr[0]}/userfeedback`)
    }
    else {
      // 使用正则表达式提取国际化代码
      const match = request.pathname.match(/^(\/[a-z]{2}-[a-z]{2})/i)
      const locale = match?.[1] || ''
      navigateTo(`/m${[locale, request.search].filter(Boolean).join('/')}`)
    }
  }

  return {
    provide: {
      isMobile: () => isMobile,
      isTablet: () => isTablet,
      isDesktop: () => isDesktop,
    },
  }
})
