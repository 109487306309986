/* eslint-disable no-console */

import cookie from 'js-cookie'

if (import.meta.client) {
  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/pc-sw.js', { /* scope: '/' */ })
        .then((registration) => {
          console.log('sw registered')
          if (registration.installing) {
            cookie.remove('hopegoo-sw-active-pc')
            console.log('正在安装 service worker')
          }
          else if (registration.waiting) {
            console.log('已安装 service worker installed')
          }
          else if (registration.active) {
            console.log('激活 service worker')
            signSWActive()
          }
        })
        .catch((err) => {
          console.warn('sw register failed', err)
        })

      navigator.serviceWorker.addEventListener('controllerchange', () => {
        console.log('已激活 Service worker')
        // 在这里可以执行 Service Worker 激活后的操作
        signSWActive()
      })
    })

    function signSWActive() {
      fetch('/_nuxt/builds/latest.json').then(res => res.json()).then((data) => {
        if (data && data.id) {
          cookie.set('hopegoo-sw-active-pc', data.id, { expires: 7 })
        }
      }).catch((err) => {
        console.error('fetch latest.json failed', err)
      })
    }
  }
  else {
    console.warn('serviceWorker not in navigator')
  }
}
