import revive_payload_client_IPIFaGUHtd from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/node_modules/.pnpm/nuxt@3.14.1592_eslint@8.40.0_rollup@4.27.3_sass@1.62.1_terser@5.36.0_typescript@5.0.4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_xGpEEdESbb from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/node_modules/.pnpm/nuxt@3.14.1592_eslint@8.40.0_rollup@4.27.3_sass@1.62.1_terser@5.36.0_typescript@5.0.4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_aMTvaaguAL from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/node_modules/.pnpm/nuxt@3.14.1592_eslint@8.40.0_rollup@4.27.3_sass@1.62.1_terser@5.36.0_typescript@5.0.4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_T61wxYIaje from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/node_modules/.pnpm/nuxt@3.14.1592_eslint@8.40.0_rollup@4.27.3_sass@1.62.1_terser@5.36.0_typescript@5.0.4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_hjBjyaK9hF from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/node_modules/.pnpm/nuxt@3.14.1592_eslint@8.40.0_rollup@4.27.3_sass@1.62.1_terser@5.36.0_typescript@5.0.4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_3QT9VBvpoV from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/node_modules/.pnpm/nuxt@3.14.1592_eslint@8.40.0_rollup@4.27.3_sass@1.62.1_terser@5.36.0_typescript@5.0.4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_vtLPTQDrlC from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/node_modules/.pnpm/nuxt@3.14.1592_eslint@8.40.0_rollup@4.27.3_sass@1.62.1_terser@5.36.0_typescript@5.0.4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_9L5mKWVqqU from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@4.27.3_typescript@5.0.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/.nuxt/components.plugin.mjs";
import prefetch_client_J18QbbJll4 from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/node_modules/.pnpm/nuxt@3.14.1592_eslint@8.40.0_rollup@4.27.3_sass@1.62.1_terser@5.36.0_typescript@5.0.4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/.nuxt/pwa-icons-plugin.ts";
import pwa_client_mDswzLp8Tk from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/node_modules/.pnpm/@vite-pwa+nuxt@0.10.6_rollup@4.27.3_workbox-build@7.3.0_workbox-window@7.3.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import i18n_DN9lWGbqeE from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/node_modules/.pnpm/@nuxtjs+i18n@8.0.1_rollup@4.27.3/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import element_plus_teleports_plugin_h4Dmekbj62 from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/.nuxt/element-plus-injection.plugin.mjs";
import plugin_g8Iv0u31nV from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.4.11_pinia@2.1.1_rollup@4.27.3/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import elementPlus_4dpxaT8lfB from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/plugins/elementPlus.ts";
import deviceDetect_FlJRUUXN8X from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/plugins/deviceDetect.ts";
import i18nHookPlugin_WBG37AuiFD from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/plugins/i18nHookPlugin.ts";
import vueClickAway_28t6fduOOS from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/plugins/vueClickAway.ts";
import vueInjectApp_Ez6a9GiVg8 from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/plugins/vueInjectApp.ts";
export default [
  revive_payload_client_IPIFaGUHtd,
  unhead_xGpEEdESbb,
  router_aMTvaaguAL,
  payload_client_T61wxYIaje,
  navigation_repaint_client_hjBjyaK9hF,
  check_outdated_build_client_3QT9VBvpoV,
  chunk_reload_client_vtLPTQDrlC,
  plugin_vue3_9L5mKWVqqU,
  components_plugin_KR1HBZs4kY,
  prefetch_client_J18QbbJll4,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_mDswzLp8Tk,
  i18n_DN9lWGbqeE,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  plugin_g8Iv0u31nV,
  elementPlus_4dpxaT8lfB,
  deviceDetect_FlJRUUXN8X,
  i18nHookPlugin_WBG37AuiFD,
  vueClickAway_28t6fduOOS,
  vueInjectApp_Ez6a9GiVg8
]