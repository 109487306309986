export const defaultLangs = [
  {
    configDesc: '<p>简体中文</p>',
    configUuid: 'lcc6f335979715f47e1a23b01f75a3265a9',
    countryCode: '86',
    currency: 'CNY',
    domain: 'www.ly.com',
    icon: 'flag-cn',
    imageUrl: '//file.hopegoocdn.com/hopegoo/web-pc/images/common/img/flag/cn.png',
    isValid: 1,
    language: 'ZH',
    languageName: '简体中文',
    locale: 'zh-cn',
    regionCode: 'CN',
  },
  {
    configDesc: '<p>繁体中文-香港</p>',
    configUuid: 'lccc033200f598b4bf3ace2d8aaeb6b6f02',
    countryCode: '86',
    currency: 'HKD',
    domain: '',
    icon: 'flag-fan',
    imageUrl: '//file.hopegoocdn.com/hopegoo/web-pc/images/common/img/flag/hk.png',
    isValid: 1,
    language: 'HK',
    languageName: '繁體中文 (香港)',
    locale: 'zh-hk',
    regionCode: 'HK',
  },
  {
    configDesc: '<p>繁体中文-台湾</p>',
    configUuid: 'lcc959d54151bdb4b528c4d5091bdd83d45',
    countryCode: '86',
    currency: 'TWD',
    domain: '',
    icon: 'flag-fan',
    imageUrl: '//file.hopegoocdn.com/hopegoo/web-pc/images/common/img/flag/hant.png',
    isValid: 1,
    language: 'TW',
    languageName: '繁體中文 (台湾)',
    locale: 'zh-tw',
    regionCode: 'TW',
  },
  {
    configDesc: '<p>英语-美国</p>',
    configUuid: 'lcc6e21fc539d4e4f1f890b6e06044cd9d2',
    countryCode: '44',
    currency: 'USD',
    domain: '',
    icon: 'flag-us',
    imageUrl: '//file.hopegoocdn.com/hopegoo/web-pc/images/common/img/flag/us.png',
    isValid: 1,
    language: 'EN',
    languageName: 'English (United States)',
    locale: 'en-us',
    regionCode: 'US',
  },
  {
    configDesc: '',
    configUuid: 'lccdf87fb5faa534c7780eec7a8edff3e9b',
    countryCode: '',
    currency: '',
    domain: '',
    icon: 'flag-xx',
    imageUrl: '//file.hopegoocdn.com/hopegoo/web-pc/images/common/img/flag/en-ww.png',
    isValid: 1,
    language: 'EN',
    languageName: 'English',
    locale: 'en-xx',
    regionCode: 'EN',
  },
  {
    configDesc: '',
    configUuid: 'lcca8abd2f84f0644829274f4f6d5a45ad7',
    countryCode: '',
    currency: 'GBP',
    domain: '',
    icon: 'flag-gb',
    imageUrl: '//file.hopegoocdn.com/hopegoo/web-pc/images/common/img/flag/gb.png',
    isValid: 1,
    language: 'EN',
    languageName: 'English (United Kingdom)',
    locale: 'en-gb',
    regionCode: 'GB',
  },
  {
    configDesc: '',
    configUuid: 'lcca5fce4bc36f1407785f8131dccb81d69',
    countryCode: '',
    currency: 'HKD',
    domain: '',
    icon: 'flag-hk',
    imageUrl: '//file.hopegoocdn.com/hopegoo/web-pc/images/common/img/flag/hk.png',
    isValid: 1,
    language: 'EN',
    languageName: 'English (Hong Kong)',
    locale: 'en-hk',
    regionCode: 'HK',
  },
  {
    configDesc: '',
    configUuid: 'lcca5fce4bc3671407c85f8131dccb81d69',
    countryCode: '',
    currency: 'HKD',
    domain: '',
    icon: 'flag-de',
    imageUrl: '//file.hopegoocdn.com/hopegoo/web-pc/images/common/img/flag/de.png',
    isValid: 1,
    language: 'DE',
    languageName: 'Deutsch',
    locale: 'de-de',
    regionCode: 'DE',
  },
  {
    configDesc: '',
    configUuid: 'lcca6e62d91798a4f35a924f5ba7baffcc4',
    countryCode: '',
    currency: 'SGD',
    domain: '',
    icon: 'flag-sg',
    imageUrl: '//file.hopegoocdn.com/hopegoo/web-pc/images/common/img/flag/sg.png',
    isValid: 1,
    language: 'SG',
    languageName: 'English (Singapore)',
    locale: 'en-sg',
    regionCode: 'SG',
  },
  {
    configDesc: '',
    configUuid: 'lcc921f01f798a1420eaea41a55f55b4834',
    countryCode: '',
    currency: 'AUD',
    domain: '',
    icon: 'flag-au',
    imageUrl: '//file.hopegoocdn.com/hopegoo/web-pc/images/common/img/flag/au.png',
    isValid: 1,
    language: 'EN',
    languageName: 'English (Australia)',
    locale: 'en-au',
    regionCode: 'AU',
  },
  {
    configDesc: '',
    configUuid: 'lcce415f99f39ff4da995bc1fd8286c4230',
    countryCode: '',
    currency: 'MYR',
    domain: '',
    icon: 'flag-my',
    imageUrl: '//file.hopegoocdn.com/hopegoo/web-pc/images/common/img/flag/my.png',
    isValid: 1,
    language: 'EN',
    languageName: 'English (Malaysia)',
    locale: 'en-my',
    regionCode: 'MY',
  },
  {
    configDesc: '',
    configUuid: 'lcc51d1d97b287c4f96ba214ea61a6947e3',
    countryCode: '',
    currency: 'PHP',
    domain: '',
    icon: 'flag-ph',
    imageUrl: '//file.hopegoocdn.com/hopegoo/web-pc/images/common/img/flag/ph.png',
    isValid: 1,
    language: 'EN',
    languageName: 'English (Philippines)',
    locale: 'en-ph',
    regionCode: 'PH',
  },
  {
    configDesc: '',
    configUuid: 'lcc27aaba3f573347839dade94b4c2d92cd',
    countryCode: '',
    currency: 'NZD',
    domain: '',
    icon: 'flag-nz',
    imageUrl: '//file.hopegoocdn.com/hopegoo/web-pc/images/common/img/flag/nz.png',
    isValid: 1,
    language: 'EN',
    languageName: 'English (New Zealand)',
    locale: 'en-nz',
    regionCode: 'NZ',
  },
  {
    configDesc: '',
    configUuid: 'lcc204cab2e5fbf4c1b9ea5dd84395421fd',
    countryCode: '',
    currency: 'CAD',
    domain: '',
    icon: 'flag-ca',
    imageUrl: '//file.hopegoocdn.com/hopegoo/web-pc/images/common/img/flag/ca.png',
    isValid: 1,
    language: 'EN',
    languageName: 'English (Canada)',
    locale: 'en-ca',
    regionCode: 'CA',
  },
  {
    configDesc: '',
    configUuid: 'lcc585a3e738a284bc89f3f025f45e207b3',
    countryCode: '',
    currency: 'JPY',
    domain: '',
    icon: 'flag-jp',
    imageUrl: '//file.hopegoocdn.com/hopegoo/web-pc/images/common/img/flag/jp.png',
    isValid: 1,
    language: 'JP',
    languageName: '日本語',
    locale: 'ja-jp',
    regionCode: 'JP',
    sortValue: 0,
  },
]

export const defaultCurrencyList = [
  {
    currency: 'United Arab Emirates Dirham',
    currencyCode: 'AED',
    isSupport: 0,
    symbol: 'د.إ',
  },
  {
    currency: 'Australian Dollar',
    currencyCode: 'AUD',
    isSupport: 1,
    symbol: '$',
  },
  {
    currency: 'Brazilian Real',
    currencyCode: 'BRL',
    isSupport: 0,
    symbol: 'R$',
  },
  {
    currency: 'Canadian Dollar',
    currencyCode: 'CAD',
    isSupport: 1,
    symbol: '$',
  },
  {
    currency: 'Swiss Franc',
    currencyCode: 'CHF',
    isSupport: 0,
    symbol: 'CHF',
  },
  {
    currency: 'Chinese Yuan',
    currencyCode: 'CNY',
    isSupport: 0,
    symbol: '¥',
  },
  {
    currency: 'Danish Krone',
    currencyCode: 'DKK',
    isSupport: 0,
    symbol: 'kr',
  },
  {
    currency: 'Euro',
    currencyCode: 'EUR',
    isSupport: 1,
    symbol: '€',
  },
  {
    currency: 'British Pound',
    currencyCode: 'GBP',
    isSupport: 1,
    symbol: '£',
  },
  {
    currency: 'Hong Kong Dollar',
    currencyCode: 'HKD',
    isSupport: 1,
    symbol: '$',
  },
  {
    currency: 'Indonesian Rupiah',
    currencyCode: 'IDR',
    isSupport: 0,
    symbol: 'Rp',
  },
  {
    currency: 'Indian Rupee',
    currencyCode: 'INR',
    isSupport: 1,
    symbol: '₹',
  },
  {
    currency: 'Japanese Yen',
    currencyCode: 'JPY',
    isSupport: 1,
    symbol: '¥',
  },
  {
    currency: 'Korean Won',
    currencyCode: 'KRW',
    isSupport: 0,
    symbol: '₩',
  },
  {
    currency: 'Macau Pataca',
    currencyCode: 'MOP',
    isSupport: 0,
    symbol: 'P',
  },
  {
    currency: 'Mexican Peso ',
    currencyCode: 'MXN',
    isSupport: 0,
    symbol: '$',
  },
  {
    currency: 'Malaysian Ringgit',
    currencyCode: 'MYR',
    isSupport: 1,
    symbol: 'RM',
  },
  {
    currency: 'Norwegian Krone',
    currencyCode: 'NOK',
    isSupport: 0,
    symbol: 'kr',
  },
  {
    currency: 'New Zealand Dollar',
    currencyCode: 'NZD',
    isSupport: 1,
    symbol: '$',
  },
  {
    currency: 'Philippine Peso',
    currencyCode: 'PHP',
    isSupport: 1,
    symbol: '₱',
  },
  {
    currency: 'Polish Zloty',
    currencyCode: 'PLN',
    isSupport: 0,
    symbol: 'zł',
  },
  {
    currency: 'Russian Ruble',
    currencyCode: 'RUB',
    isSupport: 0,
    symbol: '₽',
  },
  {
    currency: 'Saudi Riyal',
    currencyCode: 'SAR',
    isSupport: 0,
    symbol: '﷼',
  },
  {
    currency: 'Swedish Krona',
    currencyCode: 'SEK',
    isSupport: 0,
    symbol: 'kr',
  },
  {
    currency: 'Singapore Dollar',
    currencyCode: 'SGD',
    isSupport: 1,
    symbol: '$',
  },
  {
    currency: 'Thai Baht',
    currencyCode: 'THB',
    isSupport: 1,
    symbol: '฿',
  },
  {
    currency: 'Turkish Lira',
    currencyCode: 'TRY',
    isSupport: 0,
    symbol: '₺',
  },
  {
    currency: 'New Taiwan Dollar',
    currencyCode: 'TWD',
    isSupport: 0,
    symbol: 'NT$',
  },
  {
    currency: 'United States Dollar',
    currencyCode: 'USD',
    isSupport: 1,
    symbol: '$',
  },
  {
    currency: 'Vietnamese Dong',
    currencyCode: 'VND',
    isSupport: 0,
    symbol: '₫',
  },
  {
    currency: 'Israeli New Shekel',
    currencyCode: 'ILS',
    isSupport: 0,
    symbol: '₪',
  },
  {
    currency: 'Qatari Riyal',
    currencyCode: 'QAR',
    isSupport: 0,
    symbol: '﷼',
  },
]
