import validate from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/node_modules/.pnpm/nuxt@3.14.1592_eslint@8.40.0_rollup@4.27.3_sass@1.62.1_terser@5.36.0_typescript@5.0.4/node_modules/nuxt/dist/pages/runtime/validate.js";
import check_45route_45global from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/middleware/checkRoute.global.ts";
import platform_45global from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/src/middleware/platform.global.ts";
import manifest_45route_45rule from "/data/jenkinsslave/workspace/furt_hopegoo.web.pc_stage/node_modules/.pnpm/nuxt@3.14.1592_eslint@8.40.0_rollup@4.27.3_sass@1.62.1_terser@5.36.0_typescript@5.0.4/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  check_45route_45global,
  platform_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}