import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import zhCn from 'element-plus/es/locale/lang/zh-cn'
import zhTw from 'element-plus/es/locale/lang/zh-tw'
import en from 'element-plus/es/locale/lang/en'
import ja from 'element-plus/es/locale/lang/ja'
import de from 'element-plus/es/locale/lang/de'

import { defineNuxtPlugin } from '#app' // 内部模块

// 创建语言映射对象
const localeMap: Record<string, any> = {
  'en-hk': en,
  'en-xx': en,
  'zh-hk': zhTw,
  'zh-cn': zhCn,
  'zh-tw': zhTw,
  'ja-jp': ja,
  'en-my': en,
  'en-ph': en,
  'en-sg': en,
  'de-de': de,
  'en-gb': en,
  'en-ca': en,
  'en-us': en,
  'en-au': en,
  'en-nz': en,
}

export default defineNuxtPlugin((nuxtApp) => {
  // 假设你已经设置了 Vue I18n
  const i18n = nuxtApp.vueApp.config.globalProperties.$i18n

  // 获取当前的 locale
  const currentLocale = i18n?.locale || 'en-xx'

  // 从映射中获取对应的 Element Plus 语言配置
  const elementPlusLocale = localeMap[currentLocale] || en

  nuxtApp.vueApp.use(ElementPlus, {
    locale: elementPlusLocale,
  })
})
