<script setup lang="ts">
</script>

<template>
  <div class="custom-header">
    <div id="init_header" />
  </div>
</template>

<style lang="scss" scoped>
.custom-header {
  position: relative;
  min-height: 81px;
  background: #fff;
  min-width: 1190px;
  box-shadow: 0 1px 10px rgba(134, 142, 155, .15);
  border-bottom: 1px solid #ebebeb;
}
</style>
