import { ElMessageBox } from 'element-plus'
import { defaultCurrencyList } from './defaultLangInfo'

/**
 * 保留几位小数
 * @param num - 数字
 * @param decimal - 保留的位数
 * @param fixedPoint - 是否保留的同时四舍五入(默认false)
 * @param forceDecimal - 是否强制保留小数(默认false)
 * @returns
 */
export function reserveDecimal(
  num: number,
  decimal: number,
  fixedPoint?: boolean,
): number
export function reserveDecimal<T extends boolean>(
  num: number,
  decimal: number,
  fixedPoint?: boolean,
  forceDecimal?: T,
): T extends true ? string : number
export function reserveDecimal(
  num: number,
  decimal: number,
  fixedPoint = false,
  forceDecimal = false,
) {
  if (isNaN(num)) { return num }
  const saveDecimalSuffix = fixedPoint ? 0 : 1
  const saveDecimal = decimal + saveDecimalSuffix
  const numStr = num.toFixed(saveDecimal)
  const result = numStr.substring(0, numStr.length - saveDecimalSuffix)
  if (forceDecimal === true) {
    return result.replace(/\.$/, '')
  }
  else {
    return Number(result)
  }
}

function parse(str: any) {
  try {
    return JSON.parse(str)
  }
  catch (error) { }
}
function stringify(obj: object) {
  try {
    return JSON.stringify(obj)
  }
  catch (error) { }
  return ''
}
function json(obj: object) {
  return parse(stringify(obj)) || obj
}

/**
 * JSON 转 Query String
 * @param {Object} obj JSON 对象
 * @return {String} 转换后的 Query String
 */
function jsonToQuery(obj: object) {
  const query: any[] = []
  const newJson = json(obj)
  Object.keys(newJson).forEach((key) => {
    query.push([key, newJson[key]].join('='))
  })
  return query.join('&')
}

/**
 * 删除对象里值为空的key
 * @param obj
 */
function delEmpty(obj: { [key: string]: any }) {
  for (const key in obj) {
    const val = obj[key]
    if (val === '' || val === null || val === undefined) {
      delete obj[key]
    }
  }
}

function go(url: string) {
  url && setTimeout(() => {
    location.href = url
  }, 100)
}
function open(url: string) {
  url && setTimeout(() => {
    window.open(url)
  }, 100)
}

function isValidCurrency(currency: string) {
  // 从defaultCurrencyList中找到currency currencyCode相等
  if (!currency) { return false }

  const currencyInfo = defaultCurrencyList.find((item) => {
    return item.currencyCode === currency
  })

  if (!currencyInfo) { return false }
  return true
}

/**
 * 公共埋点方法
 * @param {string} category 如果传空字符串 值为 '国际站PC-首页'
 * @param {string} action 如果传空字符串 值为 '点击'
 * @param {string} label
 * @param {string} value
 */
function sendEvent(category: string, action: string, label: string, value: string) {
  if (!process.client) { return }

  let i = 1; const t = setInterval(setEvent, 1000)
  setEvent()

  function setEvent() {
    (i++ > 10) && clearInterval(t)
    if (!window.collectEvent || typeof window.collectEvent !== 'function') { return }

    window.collectEvent('commenEvent', {
      category: category || '国际站PC-首页',
      action: action || '点击',
      label: label || '',
      value: value || '',
      refid: getUrlParam('refid') || undefined,
    })

    // _tcTraObj._tcTrackEvent(category || '国际站PC-首页', action || '点击', label || '', value || '')
    console.log(category, action, label, value)
    clearInterval(t)
  }
}

// 获取当前时间
function getCurrentDateTime() {
  const now = new Date()
  const year = now.getFullYear()
  const month = String(now.getMonth() + 1).padStart(2, '0')
  const day = String(now.getDate()).padStart(2, '0')
  const hour = String(now.getHours()).padStart(2, '0')
  const minute = String(now.getMinutes()).padStart(2, '0').substring(0, 1)

  return `${year}${month}${day}${hour}${minute}`
}

/**
 * 获取url中的参数
 * @param {string} name
 * @return {string} 参数值
 * @example
 *  const refid = $utils.getUrlParam('refid')
 */
function getUrlParam(name: string) {
  const route = useRoute()
  const query = route.query
  return `${query[name] || ''}`
}

/**
 * 获取refid
 * 从链接和cookie中获取refid
 * @return {string} refid
 */
function getRefid() {
  const refid = getUrlParam('refid')
  if (refid && refid.length > 0) { return refid }

  const CNSEInfoCookies = useComCookie('CNSEInfo', { disEncode: true })
  if (CNSEInfoCookies.value && CNSEInfoCookies.value.length > 0) {
    const CNSEInfoArray = CNSEInfoCookies.value.split('&')
    for (let i = 0; i < CNSEInfoArray.length; i++) {
      const item = CNSEInfoArray[i]
      const tempArray = item.split('=')
      if (tempArray.length === 2) {
        const key = tempArray[0]
        const value = tempArray[1]
        if (key === 'RefId' && value && value.length > 0) { return value }
      }
    }
  }
  return ''
}

/**
 * 将数值转换为千分位格式
 * @param num 数值
 * @returns 千分位格式的字符串
 */
function formatNumToMillennial(num: string | number): string | number {
  try {
    let numStr = num.toString()
    const reg = /(-?\d+)(\d{3})/

    while (reg.test(numStr)) {
      numStr = numStr.replace(reg, '$1,$2')
    }
    return numStr
  }
  catch (error) {
    return num
  }
}

/**
 * 适配国际化场景的 messageBox
 * @param t
 * @param msg
 * @param opt
 * @returns
 */
function messageBox(t: (key: string) => string, msg: string, opt?: { [key: string]: any }) {
  const config = {
    showCancelButton: false,
    confirmButtonText: t('messageBox.b_button_knew'),
    cancelButtonText: t('messageBox.b_button_cancel'),
    ...(opt || {}),
  }

  if (opt?.confirmButtonText) {
    config.confirmButtonText = t(`messageBox.b_button_${opt.confirmButtonText}`)
  }
  if (opt?.cancelButtonText) {
    config.cancelButtonText = t(`messageBox.b_button_${opt.cancelButtonText}`)
  }

  return ElMessageBox.confirm(msg || t('messageBox.msg_error'), config)
}

function getHost() {
  const runtimeConfig = useRuntimeConfig()
  const env = runtimeConfig.public.envData.VITE_PACK_ENV
  let domain = ''

  if (env === 'prod') {
    domain = ''
  }
  else if (env === 'dev' || env === 'test') {
    domain = '.t'
  }
  else if (env === 'qa') {
    domain = '.qa'
  }

  return `https://www${domain}.hopegoo.com`
}

export function getBackUrl(url: string, defaultUrl: string) {
  const reg = /^http(s)?:\/\//

  if (!reg.test(url)) {
    return url
  }

  const location = new URL(url)

  const host = location.host

  if (!host.includes('.hopegoo.com')) {
    return defaultUrl
  }
  else {
    return url
  }
}

export const $utils = {
  isValidCurrency,
  parse,
  stringify,
  json,
  jsonToQuery,
  delEmpty,
  go,
  open,
  sendEvent,
  getCurrentDateTime,
  getUrlParam,
  getRefid,
  formatNumToMillennial,
  messageBox,
  getHost,
}
