<script lang="ts" setup>
const props = defineProps({
  error: Object,
})

const { t } = useLang()

useHead({
  title: t('home.b_error_404'),
  meta: [
    { name: 'keywords', content: t('common.b_tdk_keywords') },
    { name: 'description', content: t('common.b_tdk_description') },
    { name: 'author', content: t('common.b_author') },
    { name: 'copyright', content: t('common.b_company') },
  ],
  bodyAttrs: {},
  script: [],
  link: [],
})
onMounted(() => {
  if (process.client) {
    if (props.error) {
      console.log('error', props.error)

      try {
        const tq = window.tq
        // 手动上报错误
        if (tq) {
          tq.reportErr(props.error)
        }
      }
      catch (error) {

      }
    }
  }
})
</script>

<template>
  <defaultHeader />
  <div class="center-container">
    <div class="content">
      <p class="error_msg">
        {{ t('home.b_error_404') }}
      </p>
      <img class="error-img" src="//file.hopegoocdn.com/hopegoo/web-pc/images/404/img/seabed.png" alt="404">
    </div>
  </div>
  <defaultFooter />
</template>

<style lang="scss" scoped>
.center-container {
  background-color: white;

  .content {
    margin: 130px auto;

    position: relative;
    width: 1190px;
    background: #fff;
    text-align: center;
    color: #333;

    display: flex;
    flex-direction: column;

    .error_msg {
      position: absolute;
      top: 162px;
      left: 50%;

      text-align: center;
      font-size: 28px;
      color: #333;

      transform: translate(-50%, -50%);
    }

    .error-img {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
