import dayjs from 'dayjs'
import 'dayjs/locale/en'
import 'dayjs/locale/zh-hk'
import 'dayjs/locale/zh-tw'
import 'dayjs/locale/ja'

declare module 'dayjs' {
  interface Dayjs {
    /**
     * 格式化日期 YYYY-MM-DD
     */
    formatDate(): string
  }
}
dayjs.prototype.formatDate = function () {
  return this.format('YYYY-MM-DD')
}

declare global {
  interface Window {
    dayjs: typeof dayjs
  }
}
process.client && (window.dayjs = dayjs) // cl测试

export default dayjs

const localesMap: { [key: string]: string } = {
  'en-us': 'en',
  'ja-jp': 'ja',
}

function getConverLocale(locale: string) {
  let tempLocale = locale
  if (localesMap[locale] as any) { tempLocale = localesMap[locale] }
  else {
    if (tempLocale.startsWith('zh')) {
      tempLocale = 'zh-hk'
    }
    else {
      tempLocale = 'en'
    }
  }
  return tempLocale
}

function configDayjsLocal(locale: string) {
  const tempLocale = getConverLocale(locale)
  dayjs.locale(tempLocale)
}

/**
 * 获取dayjs的ls
 * @param locale
 */
function getDayjsLsForLocal(locale: string) {
  const tempLocale = getConverLocale(locale)
  const tempLs = dayjs.Ls[tempLocale]
  return tempLs
}

export { configDayjsLocal, getDayjsLsForLocal }
