<script lang="ts" setup>
import './register'
import { useCommonConfig } from '~/store/common'
import { InitApp } from '~/utils/initApp'
import { useFetch } from '#app'
import '@overseas/tcdesign/dist/tcdesign.css'

InitApp()

const config = useRuntimeConfig()
const buildTime = config.public.buildTime

if (import.meta.client) {
  // eslint-disable-next-line no-console
  console.log(`%c[BuildTime]: ${buildTime}`, 'color: #01875f; font-size: 1.5em; font-weight: bold;')
}

const { locale, mergeLocaleMessage } = useI18n()

try {
  const { data } = await useFetch('/_fapi/fetchLatestI18', {
    method: 'POST',
    body: JSON.stringify({ lang: locale.value === 'en-xx' ? 'en-us' : locale.value }),
  })

  if (data && data.value) {
    mergeLocaleMessage(locale.value, data.value)
  }
}
catch (error) {
  console.warn('apply 语言包失败', error)
}

const commonConfig = useCommonConfig()
if (process.client) {
  commonConfig.getCommonConfig()
}
</script>

<template>
  <Html :lang="locale">
    <Body class="min-h-screen antialiased text-gray-800 transition-colors duration-300 bg-white w-max-full">
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
    </Body>
  </Html>
</template>

<style lang="scss">
@use "~/assets/css/element-theme.scss";

body {
  min-width: 1190px;
}
</style>
