export function useDomainConfig(config: any = {}) {
  const runtimeConfig = useRuntimeConfig()
  // const VITE_PACK_ENV = runtimeConfig.public.envData.VITE_PACK_ENV

  const configDomain = runtimeConfig.public.envData.VITE_Domain || ''
  let time = 60 * 60 * 24 * 30
  if (config.time) { time = config.time }

  const returnConfig: any = {
    domain: configDomain,
    maxAge: time,
  }

  if (config.disEncode) {
    returnConfig.encode = (value: any) => { return value }
  }

  return returnConfig
}
