/**
*  nuxt项目目录/composables/http.ts
*/
//  基于useFetch()的网络请求封装
import * as Sentry from '@sentry/vue'

// 定义ts变量类型接口
interface HttpParms {
  baseURL?: string // 请求的基本URL，即后台服务器地址，（若服务器请求地址只有一个，可不填）
  url: string // 请求api接口地址
  method?: any // 请求方法
  query?: any // 添加查询搜索参数到URL
  body?: any // 请求体
  headers?: any // 请求头
  abortController?: AbortController | {}
}

interface ResponseData {
  code: number
  data: any
  msg: string
  requestId: string
  message?: string
  status?: number
  body?: any
}

/**
 * 网络请求方法
 * @param obj 请求参数
 * @returns 响应结果
 */

export function http(obj: HttpParms) {
  const runtimeConfig = useRuntimeConfig()
  const BASEURL = runtimeConfig.public.envData.VITE_API_HOST
  const { locale } = vueApp.getCurrentInstance().$i18n

  let url = (obj.baseURL ?? BASEURL) + obj.url

  if (/(http|https):/.test(obj.url)) {
    url = obj.url
  }

  const res = new Promise<ResponseData>((resolve, reject) => {
    const controller = obj.abortController ?? {} as any
    const signal = 'AbortController' in window ? controller.signal : undefined

    $fetch(
      url,
      {
        method: obj.method ?? 'GET',
        query: obj?.query ?? '',
        body: obj?.body ?? undefined,
        credentials: 'include',
        signal,
        onRequest({ request, options }) {
          options.headers = Object.assign({
            'HopeGoo-Token': useComCookie('sectoken').value || '',
            'HopeGoo-Platform': 'pc',
            'HopeGoo-Locale': locale,
            'HopeGoo-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
            'HopeGoo-Currency': useComCookie('currency').value || '',
          }, obj.headers || {})
        },
        onRequestError({ request, options, error }) {
          if (error.name === 'AbortError') {
            console.log('Fetch request has been aborted')
          }
          else {
            console.error('请求失败: ', error.message)
            Sentry.captureException(error) // 使用 Sentry 上报请求错误
            reject(error)
          }
        },
        onResponse({ request, response, options }) {
          if (!response.ok) {
            Sentry.captureException(new Error(`API Error: ${response.status} ${response.statusText}`)) // 上报非 200 状态码
          }
          resolve(response._data)
        },
        onResponseError({ request, response, options }) {
          console.error('响应错误')
          Sentry.captureException(new Error(`Response Error: ${response.status} ${response.statusText}`)) // 上报响应错误
        },
      },
    )
  })
  return res
}

export function httpPost(url: string, params: any, option?: any) {
  return http({
    url,
    method: 'POST',
    body: params,
    ...option,
  })
}
export function httpGet(url: string, params: any, option?: any) {
  return http({
    url,
    method: 'GET',
    query: params,
    ...option,
  })
}

export interface RequestExtraParams {
  /**
   * 扩展请求头
   *
   * 如果增加了`gomock`字段，请求会走到YApi的mock环境
   *  */
  headers?: any
  /**
   * 请求取消方法，
   * 返回一个Promise，
   * 当这个Promise比实际请求先返回时，请求返回的是该cancel方法返回的值
   */
  cancel?: Promise<string | undefined>
  /** 请求是否需要携带clientinfo */
  includeClientInfo?: boolean
  /** request cancellation token */
  cancelToken?: symbol | string | number
  /** 请求超时时间, 默认10s，单位是ms */
  timeout?: number
  /** 是否显示loading， 如果使用tclist 分页加载数据，不推荐,会多次回调load */
  loading?: boolean
}

export interface RequestWrappedParams extends RequestExtraParams {
  /** http method */
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'OPTIONS' | 'PATCH' | 'HEAD'
  url: string
  /** url query */
  query?: object
  /** post json data */
  data?: any
  /** 请求内容类型 */
  contentType?: ContentType
  /** 是否只返回body（默认情况为true） */
  responseOnlyBody?: boolean
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

/** 请求错误的返回内容 */
export interface RequestFailResponse<T = unknown> {
  /** 对应的状态码 */
  status: number
  /** 对应的错误信息 */
  message: string
  /** 异常请求的完整response */
  reason: T
  /** 请求的trace id */
  id?: string
  /** 请求地址 */
  __url?: string
}

/**
 * 添加扩展的只读属性
 * @param body
 * @param props
 */
function _addReadableOnlyExtendsProps<T extends object>(
  body: T,
  props: Record<string, any>,
) {
  // 如果有body，添加服务端请求的时间戳和traceid
  if (typeof body === 'object' && body !== null) {
    // 添加时间戳和traceid
    Object.defineProperties(body, {
      ...Object.keys(props).reduce((acc, key) => {
        acc[key] = {
          value: props[key],
          enumerable: false,
          writable: false,
          configurable: false,
        }
        return acc
      }, {} as PropertyDescriptorMap),
    })
  }
}

/**
 * 非hopegooApp环境下的请求
 * @param param0
 * @param _basePath
 * @returns
 */
export function request({
  method,
  url,
  data,
  cancel,
  headers,
  contentType,
  includeClientInfo = true,
  responseOnlyBody = true,
}: RequestWrappedParams) {
  // 请求路径走本地代理
  const requestPath = url
  const beginTime = Date.now()

  // 如果需要携带clientinfo
  if (includeClientInfo) {
    if (method === 'POST') {
      data.clientInfo = ''
    }
  }
  /** 用户token */
  const authToken = useComCookie('sectoken').value || ''

  const requestFetching = fetch(requestPath, {
    method,
    mode: 'cors',
    headers: {
      'Content-Type': contentType || 'application/json',
      // 登陆信息请求头
      'HopeGoo-Token': authToken,
      // 货币请求头
      'HopeGoo-Currency': useComCookie('currency').value || '',
      // 语言和地区请求头
      'HopeGoo-Locale': useComCookie('locale').value || '',
      'HopeGoo-Platform': 'PC',
      ...(headers || {}),
      // 设备时区
      'HopeGoo-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    body: method.toUpperCase() === 'POST' ? JSON.stringify(data) : undefined,
  }).then(async (response) => {
    if (!response.ok) {
      const responseText = await response.text()
      // 网络请求状态异常处理
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject({
        status: response.status,
        message: response.statusText,
        reason: responseText,
        id: response.headers.get('Simple-Traceid'),
      })
    }
    else {
      const timestamp = response.headers.get('Simple-Timestamp')
      const traceId = response.headers.get('Simple-Traceid')
      const rspBody = await response.json()
      const { status, message, body } = rspBody
      const responseData = responseOnlyBody ? body : rspBody
      // 业务状态异常处理
      if (status !== 200) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject({
          status,
          message,
          reason: responseData,
          id: traceId,
          timestamp,
        } as RequestFailResponse)
      }
      // 添加时间戳和traceid
      if (timestamp) {
        _addReadableOnlyExtendsProps(responseData, {
          _timestamp: String(timestamp),
          _traceid: String(traceId),
          _beginTime: beginTime,
        })
      }
      return responseData
    }
  })

  if (cancel && cancel instanceof Promise) {
    return Promise.race([requestFetching, cancel])
  }
  else {
    return requestFetching
  }
}
