export default defineNuxtPlugin((nuxtApp) => {
  //   nuxtApp.hook('i18n:registerModule', ({ oldLocale, newLocale, initialSetup, context }) => {
  //     console.log('i18n:registerModule ', oldLocale, newLocale, initialSetup)
  //   })

  // called right before setting a new locale
  nuxtApp.hook('i18n:beforeLocaleSwitch', ({ oldLocale, newLocale, initialSetup, context }) => {
    console.log('onBeforeLanguageSwitch', oldLocale, newLocale, initialSetup)
  })

  // called right after a new locale has been set
  nuxtApp.hook('i18n:localeSwitched', ({ oldLocale, newLocale }) => {
    console.log('onLanguageSwitched', oldLocale, newLocale)
    if (oldLocale !== newLocale) {
      // 切换语言后，重新加载页面
      if (process.client) {
        window.location.reload()
      }
    }
  })
})
