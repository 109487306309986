export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"facebook-domain-verification","content":"xgu1tetzbvqoax6ac1ibbv9gq39ykn"},{"name":"google-site-verification","content":"-jW_7hTi2NBzPTHTtevqCXA6aMVyEiDgmAari3VNPSI"},{"name":"baidu-site-verification","content":"ldZLnVAkO3"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/intlPC/favicon.ico"}],"style":[],"script":[{"src":"/intlPC/js/defaultHeadFooterConfig.js?v=8"},{"src":"https://file.hopegoocdn.com/hopegoo/pc-layout/staging/entry.js?v=1733990139588"},{"src":"https://file.40017.cn/tcservice/saas/cdn/tcI18n/tci18n-quality-modify@1.0.js?v=1733990139588"},{"src":"https://js.40017.cn/fed/tianqiong/tqkit.1.3.7.js"},{"src":"/intlPC/js/defaultHeadFooterConfig.js?v=12"},{"innerHTML":"var SonarConfig=({endpoint:'//app.t.hopegoo.com/monitorApi/useBehaviorLog/postData',app:'hopegoo-pc',version:'20241212155539',platform:'web-pc'})"},{"src":"//file.hopegoocdn.com/hopegoo/overseas/monitor-sdk/0.0.8-10/sonar-browser.iife.js","defer":true}],"noscript":[]}

export const appCdnURL = "https://file.hopegoocdn.com/hopegoo/web-pc/stage"

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"

export const crawlLinks = false