export default defineNuxtRouteMiddleware((to, from) => {
  // If the user is on a desktop, redirect to the mobile site
  // if (!device.desktop()) {
  //   location.href = 'https://www.hopegoo.com/m'
  // }
  if (process.client) {
    // 新增页面曝光统计
    $utils.sendEvent('国际站PC', '曝光', '页面曝光', location.href)
  }
})
